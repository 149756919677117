import { gql } from '@apollo/client';

export const EXPIRE_PERSON_MUTATION = gql(/* GraphQL */`
    mutation ExpirePerson($personId: UUID!) {
        expirePerson(input: { id: $personId }) {
            __typename
            person {
                id
                expired
                endDate
                updatedAt
            }
            errors {
                __typename
                ... on ValidationError {
                    errors {
                        fieldName
                        messages
                    }
                }
                ... on EntityLockedError {
                    id
                    entity
                    message
                    updatedAt
                }
                ... on UnknownError {
                    message
                }
            }
        }
    }
`);