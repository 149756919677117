import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Box, Button, Card } from '@mui/material';
import { GridCallbackDetails, GridColDef, GridRow, GridRowId, GridRowModel, GridRowSelectionModel } from '@mui/x-data-grid';
import LoadingButton from '@mui/lab/LoadingButton';

import { getFragmentData, gql } from '../../../__generated__';
import QuickSearchToolbar, { QuickSearchToolbarProps } from '../../Admin/components/StudentsGrid/SearchBar';
import useStudentPersonsAssignment from './hooks/useStudentPersonsAssignment';
import Table from '../../../components/Table/Table';
import { useSelectedStudent } from './hooks';
import { useManageModal } from '../components/ManageModal';
import { _PersonFragment } from '../../../__generated__/graphql';

export const STUDENT_PERSONS_LINK_QUERY = gql(/* GraphQL */`
    query StudentPersonsLink($locationId: UUID, $studentId: UUID) {
        location(id: $locationId) {
            persons(status: ACTIVE) {
                ... _person
            }
        }
    }
`);

const PERSON_FRAGMENT = gql(/* GraphQL */`
    fragment _person on Person {
        id
        firstName
        middleName
        lastName
        jobTitle
        email
        startDate
        endDate
        expired
        role {
            name
            normalizedName
        }
        students(ids: [ $studentId ], status: ACTIVE) {
                    edges {
                        node {
                            id
                            references
                        }
                    }
                    totalCount
                }
    }
`);


type RouteParams = {
    sid?: string
}

type ActionOnCallback = () => void;


const unique = <T,>(array: T[]): T[] => {
    return Array.from(new Set(array));
}

const mapEdgesToTable = (data: any) => {
    // @ts-ignore because CI/CD finds fault with this line
    const fragment = getFragmentData(PERSON_FRAGMENT, data);
    return {
        id: fragment.id,
        firstName: fragment.firstName,
        lastName: fragment.lastName,
        name: `${fragment.lastName} ${fragment.firstName}`,
        userType: fragment.role?.name,
        email: fragment.email,
        jobTitle: fragment.jobTitle,
        role: fragment.role,
        students: fragment.students
    } as const
}

function deltaSelections(a: GridRowSelectionModel, b: GridRowSelectionModel, c: GridRowSelectionModel): GridRowSelectionModel {
    const added = a.filter(x => !b.includes(x));
    const removed = b.filter(x => !a.includes(x));

    console.log('deltaSelections', ([] as GridRowSelectionModel)
        .concat(c.filter(x => !removed.includes(x)))
        .concat(added))

    return ([] as GridRowSelectionModel)
        .concat(c.filter(x => !removed.includes(x)))
        .concat(added);
}

const getPersonTableFields = (loading: boolean): GridColDef[] => {
    const columns: GridColDef[] = [
        {
            field: "firstName",
            headerName: "First Name",
            flex: 1,
            sortable: true
        },
        {
            field: "lastName",
            headerName: "Last Name",
            flex: 1,
            sortable: true
        },
        // { 
        //     field: "name",
        //     headerName: "Name", 
        //     flex: 1,
        // },
        {
            field: "userType",
            headerName: "User Type",
            flex: 1,
            sortable: true
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
            sortable: true
        },
        {
            field: "jobTitle",
            headerName: "Job Title",
            flex: 1,
            sortable: true
        }
    ];

    return columns
}

interface PersonTableLinkContainerProps {
    children: React.ReactNode
}

interface PersonTableLinkTableToolbarProps extends QuickSearchToolbarProps { }

interface PersonTableLinkProps {
    onBack: ActionOnCallback
}

interface PesonTableLinkFooterProps {
    saving: boolean,
    disabled: boolean,
    onBack: ActionOnCallback
    onSave: ActionOnCallback
}

const PersonTableLink = ({
    onBack
}: PersonTableLinkProps) => {

    const routeParams = useParams<RouteParams>();

    const { loading: loadingStudent, student, setSelectedStudent } = useSelectedStudent(routeParams.sid);

    const { data, loading } = useQuery(STUDENT_PERSONS_LINK_QUERY, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
            studentId: student?.id,
            locationId: student?.locationId,
        }
    });

    const [tableState, setTableState] = useState<{
        columns: GridColDef[],
        rows: GridRowModel[],
        selectionModel: GridRowSelectionModel,
        selections: {
            initial: GridRowSelectionModel,
            pageAssign: GridRowSelectionModel,
            prevAssign: GridRowSelectionModel,
            nextAssign: GridRowSelectionModel,
            unassign: GridRowSelectionModel,
            allSelected: GridRowSelectionModel
        },
    }>({
        columns: [],
        rows: [],
        selectionModel: [],
        selections: {
            initial: [],
            pageAssign: [], // Contains student IDs that have a relationship with person
            prevAssign: [], // Contains student IDs across page/table
            nextAssign: [], // Contains student IDs used to created a person/student relationship
            unassign: [],   // Contains student IDs used to remove the person/student relationship
            allSelected: [] // Contains student IDs for selections on table/page
        },
    });

    const [saving, setSaving] = useState<boolean>(false);

    const { save } = useStudentPersonsAssignment(routeParams.sid ?? '');

    const { openModal } = useManageModal();

    const [loadOnce, setLoadOnce] = useState<boolean>(false);

    useEffect(() => {
        // We've found that useQuery dependencies update which causes the selection model update
        // to reflect the old selections after save. To mitigate this, we only build the 
        // selection model once we have recieved data from the our query. 
        if (loading || !data || loadOnce) {
            setTableState(prevState => {
                const columns: GridColDef[] = getPersonTableFields(loading);
                return {
                    ...prevState,
                    columns
                }
            });

            return;
        }

        setTableState(prevState => {
            const columns: GridColDef[] = getPersonTableFields(loading);

            const selectionModel: GridRowSelectionModel = [];
            for (let i = 0; i < (data?.location?.persons.length ?? 0); i++) {
                const person = data?.location?.persons ? data?.location?.persons[i] : null;
                if (!person) continue;

                const personFragment = getFragmentData(PERSON_FRAGMENT, person);

                for (let j = 0; j < (personFragment.students?.edges?.length ?? 0); j++) {
                    const studentEdge = personFragment.students?.edges?.[j]
                    if (!studentEdge) continue;

                    const isAssigned = studentEdge.node.id === routeParams.sid;
                    if (isAssigned) {
                        if (tableState.selections.unassign.find(x => x === studentEdge.node.id)) continue;

                        selectionModel.push(personFragment.id);
                    }
                }
            }

            setLoadOnce(true);

            return {
                columns,
                // The filter clause is required because due to the multi-role user function of the API (currently unsupported)
                // a user that has roles other than "ADMIN" is returned. User roles are resolved in a separate resolver. This
                // separate resolver ranks the roles assigned to the person/user. If the user has multiple roles, including
                // admin, then the admin role is returned. This is because the ADMIN role is ranked the highest. 
                //
                // In reality a user having multiple roles shouldn't happen, but the system supports it, the filter
                // protects against it.
                rows: data?.location?.persons?.map(mapEdgesToTable) ?? [],
                selectionModel,
                selections: {
                    ...prevState.selections,
                    initial: selectionModel,
                    pageAssign: selectionModel,
                    prevAssign: unique(prevState.selections.prevAssign.concat(selectionModel)),
                    allSelected: selectionModel.concat(prevState.selections.nextAssign)
                }
            }
        });
    }, [loading, data, routeParams]);

    const handleSelectionModelChange = (values: GridRowId[], row: GridCallbackDetails) => {

        console.log('handleSelectionModelChange', values, row);
        // Sometimes when the page is reloaded, the Table/DataGrid component triggers automatically 
        // without any user intervention. This callback function should only trigger
        // if {@see GridControlledStateReasonLookup} actions happen. However,
        // the reason is undefined (it shouldn't be). So in this case
        // we ignore this false called to preserve our selection model
        // if (!row?.reason) return;

        const assign = values.filter(x => !tableState.selectionModel.includes(x));
        const unassign = tableState.selectionModel.filter(x => !values.includes(x));

        const uniqAssign = assign.filter(x => !tableState.selections.prevAssign.includes(x));

        setTableState(prevState => {

            const nextAssign = unique(deltaSelections(uniqAssign, unassign, prevState.selections.nextAssign));
            const nextUnassign = deltaSelections(unassign, assign, prevState.selections.unassign);
            const selectionModel = {
                ...prevState,
                selectionModel: values,
                selections: {
                    ...prevState.selections,
                    nextAssign,
                    unassign: nextUnassign,
                    allSelected: unique(values.concat(nextAssign).filter(x => x !== unassign[0]))
                }
            }

            return selectionModel;
        })
    }

    const handleSave = async () => {

        // In theory, this save event shouldn't fire because the save button should be
        // disabled. However, for completeness sake, we exit the event early if 
        // there's nothing to save.
        if (tableState.selections.nextAssign.length === 0 && tableState.selections.unassign.length === 0)
            return;

        setSaving(true);

        // When not in development mode this debug code is removed (tree shaken).
        if (process.env.NODE_ENV === 'development') {
            console.group('handleSave');
            console.log('Assign', tableState.selections.nextAssign);
            console.log('Unassign', tableState.selections.unassign);
            console.groupEnd();
        }

        const references = await save(tableState.selections.nextAssign, tableState.selections.unassign);

        setSelectedStudent(prevStudent => ({
            __typename: 'Student',
            ...prevStudent,
            references: references
        }));

        console.log('Attempt to save assignments');

        setTableState(prevState => ({
            ...prevState,
            selections: {
                ...prevState.selections,
                pageAssign: [],
                prevAssign: [],
                nextAssign: [],
                unassign: []
            }
        }))

        setSaving(false);

        openModal({
            type: 'SAVED_ASSIGNMENTS',
            onConfirm: undefined,
            cancelButtonLabel: 'Close'
        });
    }

    return (
        <PersonTableLink.Container>
            <Table
                loading={loading}
                renderToolbar={({ searchQuery, onSearch, onSearchClear }) => {
                    return (
                        <PersonTableLink.TableToolbar
                            title={!loadingStudent
                                ? `Link Users for ${student?.firstName} ${student?.lastName}`
                                : 'Loading...'
                            }
                            value={searchQuery}
                            onChange={onSearch}
                            onClickBack={onBack}
                            clearSearch={onSearchClear} />
                    )
                }}
                slots={{
                    row: (props) => {

                        const person = props.row as _PersonFragment;
                        const selected = tableState.selections.initial.includes(props.row.id);
                        const found = person.students?.edges ? person.students.edges[0] : null;

                        /*
                         * `props.selected` is not true when the row is disabled using `isRowSelectable` 🤦‍♂️
                         * so to achieve the desired result, we look at the intial selection modal i.e., 
                         * all identified person/student connections found at time of loeading the
                         * component
                         */
                        const disabled = ((found?.node?.references ?? 0) <= 1 && found && selected);

                        const handleDisabledClick = () => {
                            openModal({ type: 'NOT_ENOUGH_REFERENCES' })
                        };

                        return (
                            <div
                                style={{
                                    background: disabled ? '#F5F5F5' : 'initial',
                                    color: disabled ? '#777' : 'initial',
                                    cursor: disabled ? 'not-allowed' : 'default'
                                }}
                                onClick={disabled ? handleDisabledClick : undefined}>
                                <GridRow {...props} />
                            </div>
                        )
                    }
                }}
                isRowSelectable={(params) => {
                    const person = params.row as _PersonFragment;
                    const selected = tableState.selections.initial.includes(params.row.id);
                    const found = person.students?.edges ? person.students.edges[0] : null;

                    const disabled = !((found?.node?.references ?? 0) <= 1 && found && selected);
                    return disabled;
                }}
                selectionProps={{
                    rowSelectionModel: tableState.selections.allSelected,

                }}
                onRowSelectionModelChange={handleSelectionModelChange}
                checkboxSelection={true}
                columns={tableState.columns ?? []}
                rows={tableState.rows}
                pageSize={25}
                disableBoxShadow={true}
            />
            <PersonTableLink.Footer
                disabled={(tableState.selections.nextAssign.length === 0 && tableState.selections.unassign.length === 0)}
                saving={saving}
                onBack={onBack}
                onSave={handleSave} />
        </PersonTableLink.Container>
    )

    // return (
    //     <PersonTableLink.Container>
    //         <TablePaginated
    //             loading={loading}
    //             renderToolbar={({searchQuery, onSearch, onSearchClear}) => {
    //                 return (
    //                     <PersonTableLink.TableToolbar 
    //                         title={!loading 
    //                             ? `Link Child for ${person?.firstName} ${person?.lastName}`
    //                             : 'Loading...'
    //                         } 
    //                         value={searchQuery}
    //                         onChange={onSearch}
    //                         onClickBack={onBack} 
    //                         clearSearch={onSearchClear} />
    //                 )
    //             }}
    //             pageInfo={data?.students?.pageInfo}
    //             totalRows={data?.students?.totalCount ?? 0}
    //             columns={tableState.columns}
    //             rows={tableState.rows} 
    //             pageSize={10} 
    //             selectionProps={{
    //                 selectionModel: tableState.final.allSelected,
    //                 onRowSelectionModelChange: handleSelectionModelChange
    //             }}
    //             disableBoxShadow={true}
    //             checkboxSelection={true}
    //             onFetchMore={handleFetchMore}/>
    //         <PersonTableLink.Footer 
    //             disabled={(tableState.final.nextAssign.length === 0 && tableState.final.unassign.length === 0)} 
    //             saving={saving} 
    //             onBack={onBack} 
    //             onSave={handleSave} />
    //     </PersonTableLink.Container>
    // )
}

PersonTableLink.TableToolbar = (props: PersonTableLinkTableToolbarProps) => {
    return (
        <Box component="div">
            <QuickSearchToolbar {...props} />
        </Box>
    );
}

PersonTableLink.Container = ({ children }: PersonTableLinkContainerProps): React.ReactElement => {
    return (
        <Card
            elevation={4}
            style={{
                width: "100%",
                height: "auto",
            }}
        >
            <>{children}</>
        </Card>
    )
}

PersonTableLink.Footer = ({ disabled, saving, onSave, onBack }: PesonTableLinkFooterProps): React.ReactElement => {
    return (
        <Box
            component="div"
            sx={{ padding: "16px", display: "flex", justifyContent: "flex-end" }}
        >
            <Button
                disableElevation
                variant="contained"
                size="large"
                sx={{
                    marginRight: "16px",
                    backgroundColor: "#C4C4C4",
                    "&:hover": {
                        color: "#FFF",
                        backgroundColor: "red.main",
                    },
                }}
                onClick={onBack}
            >
                Cancel
            </Button>
            <LoadingButton
                loading={saving}
                disabled={disabled}
                disableElevation
                variant="contained"
                size="large"
                sx={{
                    backgroundColor: "primary.main",
                    "&:hover": {
                        backgroundColor: "primary.light",
                    },
                }}
                onClick={onSave}
            >
                Save
            </LoadingButton>
        </Box>
    )
}

export default PersonTableLink;