import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
    components: {

    },
    palette: {
        action: {
            disabledBackground: '#bdbdbd',
            disabled: '#9e9e9e'
        },

        primary: {
            main: "#83A47B",
            dark: "#56754f",
            light: "#b3d5aa"
        },
        secondary: {
            main: "#253D93",
            dark: "#303f9f"
        },
        white: {
            light: "#FAFAFA",
            main: "#FAFAFA",
            dark: "#F3F4F6"
        },
        indigo: {
            main: "#253D93",
            light: "#5c67c4",
            dark: "#001864"
        },
        green: {
            main: "#83A47B",
            light: "#b3d5aa",
            dark: "#56754f"
        },
        red: {
            main: "#8E1C3B",
            light: "#c34f65",
            dark: "#5b0016"
        },
        pink: {
            light: "#ffd6e3",
            main: "#d2a4b1",
            dark: "#a07582"
        },
        yellow: {
            light: "#FCD34D",
            main: "#FBBF24",
            dark: "#F59E0B"
        },
        ft_gray: {
            light: "#ffffff",
            main: "#F0EFEF",
            dark: "#bebdbd"
        },
        ft_bright_yellow: {
            light: "#ffff78",
            main: "#ffd045",
            dark: "#c89f00"
        },
        info: {
            main: "#253D93",
            light: "#5c67c4",
            dark: "#001864"
        },
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
    overrides: {
        MuiSelect:{
            root:{
                textAlign:'left'
            }
        }
   }
});

theme = responsiveFontSizes(theme);
export {
    theme
}