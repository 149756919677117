import { gql } from '../../../../../__generated__/gql';

export const PEOPLE_FOR_LINK__PERSON_FRAGMENT = gql(`
    fragment PeopleForLink__Person on Person {
        id
        firstName
        middleName
        lastName
        jobTitle
        email
        startDate
        endDate
        expired
        role {
            name
            normalizedName
        }
        students {
            ...PeopleForLink__StudentFragment
        }
    }
`);