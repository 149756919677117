import { gql } from "@apollo/client";

export const markReviewed = gql`
mutation markReviewed( $id: UUID! ) 
    {
        markWorkListAsRead( input: { id: $id } ) 
  {
    __typename
    workList {
      id
      status
    }
   }
}
`;