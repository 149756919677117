import { gql } from "@apollo/client";

export const EDIT_PERSON_MUTATION = gql(/* GraphQL */ `
  mutation EditPerson($input: EditPersonInput!) {
    editPerson(input: $input) {
      __typename
      person {
        id
        firstName
        jobTitle
        lastName
        middleName
        role {
          name
          normalizedName
        }
      }
      errors {
        __typename
        ... on ValidationError {
          message
          errors {
            fieldName
            messages
          }
        }
      }
    }
  }
`);
