import React from "react";
import { AddNote } from "../AddNote";
import ChangePassword from "../ChangePassword";
import EditUser from "../EditUser";
import AddStudent from "../AddStudent";
import LaunchOptions from "../LaunchOptions/LaunchOptions";
import { SendToParentOptions } from "../SendToParentOptions";
import SelectLocation from "../SelectLocation/SelectLocation";

export interface ISlideInContent {
  id: string;
  header: string;
  content: React.ReactNode;
}

export const EDIT_USER: ISlideInContent = {
  id: "EDIT_USER",
  header: "Add User",
  content: <EditUser />,
};

export const CHANGE_PASSWORD: ISlideInContent = {
  id: "CHANGE_PASSWORD",
  header: "Change Password",
  content: <ChangePassword />,
};

export const ADD_NOTE: ISlideInContent = {
  id: "ADD_NOTE",
  header: "Add Note",
  content: <AddNote />,
};

export const ADD_STUDENT: ISlideInContent = {
  id: "ADD_STUDENT",
  header: "Add New Child",
  content: <AddStudent />,
};

export const LAUNCH_OPTIONS: ISlideInContent = {
  id: "LAUNCH_OPTIONS",
  header: "Launch Options",
  content: <LaunchOptions />,
};

export const SEND_TO_PARENT_OPTIONS: ISlideInContent = {
  id: "SEND_TO_PARENT_OPTIONS",
  header: "Information for Parents",
  content: <SendToParentOptions />,
};

export const SELECT_LOCATION: ISlideInContent = {
  id: "SELECT_LOCATION",
  header: "Change Location",
  content: <SelectLocation />
}

export const contentTypes: ISlideInContent[] = [
  EDIT_USER,
  CHANGE_PASSWORD,
  ADD_NOTE,
  LAUNCH_OPTIONS,
];
