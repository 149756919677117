import React, { useContext, useEffect, useState } from "react";
import { ISlideInContent } from "./contentTypes";

export type SlideInContent = {
    open: boolean;
    disableClose: boolean;
    toggleDrawer: () => void,
    disableClosingDrawer: (value: boolean) => void,
    content?: ISlideInContent,
    handleContent: (content: ISlideInContent) => void
};

const SlideInContext = React.createContext<SlideInContent>({
    open: false,
    disableClose: false,
    toggleDrawer: () => { return },
    disableClosingDrawer: (value: boolean) => { return },
    content: undefined,
    handleContent: ( ) => { return }
});

SlideInContext.displayName = 'SlideInContext';

const useSlideInContext = () => useContext(SlideInContext);

interface IProps {
    children: React.ReactNode
};

interface IProps {
    children: React.ReactNode
};

const SlideInContextProvider = (props: IProps) => {
    const { children } = props;
    const [ content, setContent ] = useState<ISlideInContent>();
    const [open, setOpen] = useState(false);
    const [disableClose, setDisableClose] = useState(false);

    const handleContent = (_content: ISlideInContent) => {
        console.log(_content)
        setContent(_content)
        setOpen(true)
        return;
    };

    useEffect(() => {
        if (!open && content) {
            setOpen(true);
        };
    }, [content]);

    const toggleDrawer = () => {
        setOpen(!open)
        return;
    };

    const disableClosingDrawer = (value: boolean) => {
        setDisableClose(value);
        return;
    }

    return (
        <SlideInContext.Provider
            value={{
                open,
                disableClose,
                toggleDrawer,
                disableClosingDrawer,
                handleContent,
                content
            }}>
            {children}
        </SlideInContext.Provider>
    );
};

export { useSlideInContext, SlideInContextProvider };
