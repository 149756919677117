import React, { useContext, useState } from "react";

export type RouteContent = {
    shouldRouteToAvatar: boolean;
    shouldRouteToFamilySurvey: boolean;
    handleSetRouteToAvatar: (value: boolean) => void;
    handleSetRouteToFamilySurvey: (value: boolean) => void;
}

const RouteContext = React.createContext<RouteContent>({
    shouldRouteToAvatar: true,
    shouldRouteToFamilySurvey: true,
    handleSetRouteToAvatar: (value: boolean) => { return },
    handleSetRouteToFamilySurvey: (value: boolean) => { return }
});

RouteContext.displayName = 'RouteContext';

const useRouteContext = () => useContext(RouteContext)

const RouteContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [shouldRouteToAvatar, setShouldRouteToAvatar] = useState(true);
    const [shouldRouteToFamilySurvey, setShouldRouteToFamilySurvey] = useState(true);
    const handleSetRouteToAvatar = (value: boolean) => setShouldRouteToAvatar(value)
    const handleSetRouteToFamilySurvey = (value: boolean) => setShouldRouteToFamilySurvey(value)
    
    return (
        <RouteContext.Provider value={{ shouldRouteToFamilySurvey, shouldRouteToAvatar, handleSetRouteToAvatar, handleSetRouteToFamilySurvey }} >{children} </RouteContext.Provider>
    );
};

export { useRouteContext, RouteContextProvider };
