import { GET_PEOPLE } from "../../../features/graphql/query";
import { useLazyQuery } from "@apollo/client";
import { IUsers } from "../types";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { GetPeopleQuery } from "../../../__generated__/graphql";

const useUserData = () => {
  const [FetchUserData, { data, loading }] = useLazyQuery<GetPeopleQuery>(GET_PEOPLE, {
    fetchPolicy: "network-only",
  });
  const [users, setUsers] = useState<IUsers[]>([]);

  useEffect(() => {
    if (!loading) {
      setUsers(getFormattedData());
    }
  }, [loading]);

  const getFormattedData = (): IUsers[] => {
    if (!data?.persons) return [];

    return data.persons.map(record => ({
      id: record.id,
      name: `${record.firstName} ${record.lastName}`,
      firstName: record.firstName,
      middleName: record.middleName,
      lastName: record.lastName,
      email: record.email,
      jobTitle: record.jobTitle,
      startDate: record.startDate,
      endDate: record.endDate,
      expired: record.status === 'Expired',
      role: {
        name: record.role?.name ?? '',  
        normalizedName: record.role?.normalizedName ?? ''
      },
      roleType: record.role?.normalizedName,
      linkedData: record.students?.edges
          ? record.students?.edges.map((value: any) => ({
              ...value.node,
              name: `${value.node.lastName}, ${value.node.firstName}`,
              dob: value.node.dob
                ? format(new Date(value.node.dob), "MM/dd/yyyy")
                : "",
            }))
          : [],
    }));
  };

  return { loading, users, FetchUserData };
};

export { useUserData };
