import { useMutation } from "@apollo/client";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { gql } from "../../../../__generated__";
import { STUDENT_PERSONS_LINK_PREVIEW_QUERY } from "../StudentTableLinkPreview";
import { SELECTED_STUDENT_QUERY } from "./useSelectedStudent";

const STUDENT_PERSONS_ASSIGN_MUTATION = gql(/* GraphQL */`
    mutation StudentPersonsAssign($studentId: UUID!, $personIds: [UUID!]!) {
        assignStudentPersons(input: { studentId: $studentId, personIds: $personIds }) {
            student {
                __typename
                id
                references
            }
            errors {
                __typename
                ... on ValidationError {
                    message
                    errors {
                        fieldName
                        messages
                    }
                }
            }
        }
    }
`)

const STUDENT_PERSONS_UNASSIGN_MUTATION = gql(/* GraphQL */`
    mutation StudentPersonsUnassign($studentId: UUID!, $personIds: [UUID!]!) {
        unassignStudentPersons(input: { studentId: $studentId, personIds: $personIds }) {
            student {
                __typename
                id
                references
            }
            errors {
                __typename
                ... on ValidationError {
                    message
                    errors {
                        fieldName
                        messages
                    }
                }
            }
        }
    }
`)

const useStudentPersonsAssignment = (studentId: string) => {
    const [assign] = useMutation(STUDENT_PERSONS_ASSIGN_MUTATION, {
        refetchQueries: [{
            query: STUDENT_PERSONS_LINK_PREVIEW_QUERY,
            variables: { studentId, first: 100 }
        },
        {
            query: SELECTED_STUDENT_QUERY, 
            variables: { id: studentId }
        }]
    });

    const [unassign] = useMutation(STUDENT_PERSONS_UNASSIGN_MUTATION, {
        refetchQueries: [{
            query: STUDENT_PERSONS_LINK_PREVIEW_QUERY,
            variables: { studentId, first: 100 }
        },
        {
            query: SELECTED_STUDENT_QUERY, 
            variables: { id: studentId }
        }]
    });

    const save = async (assignments: GridRowSelectionModel, unassignments: GridRowSelectionModel): Promise<number> => {
        
        let references = 0;

        // Exit early if there are no changes to be made
        if (assignments.length === 0 && unassignments.length === 0) {
            console.trace("Attempting to save changes but no assigment or unassigment IDs are provided");
            return 0;
        }

        if (assignments.length > 0) {
            if (process.env.NODE_ENV === "development")
                console.trace(`Attempting assigning persons to student ${studentId}`, assignments);

            const response = await assign({
                variables: {
                    studentId,
                    personIds: assignments
                }
            });

            references = response.data?.assignStudentPersons.student?.references ?? 0;

            if (process.env.NODE_ENV === "development")
                console.trace(`Successfully assigned persons to student ${studentId}`);
            
            if (response.errors?.length ?? 0 > 0)
                console.error("Student Assignment Failure", response.errors);
        }
        
        if (unassignments.length > 0) {
            if (process.env.NODE_ENV === "development")
                console.trace(`Attempting unassigning persons for student ${studentId}`, assignments);

            const response = await unassign({
                variables: {
                    studentId,
                    personIds: unassignments
                }
            });

            references = response.data?.unassignStudentPersons.student?.references ?? 0;

            if (process.env.NODE_ENV === "development")
                console.trace(`Successfully unassigned persons to student ${studentId}`);

            if (response.errors?.length ?? 0 > 0)
                console.error("Student Unassignment Failure", response.errors);
        }

        return references;
    }

    return { save };
}

export default useStudentPersonsAssignment;