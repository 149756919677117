import { useMutation } from '@apollo/client';
import { FORM_ERROR } from 'final-form';

import { gql } from '../../../../../__generated__';
import { AddSessionNoteMutation, AddSessionNoteMutationVariables, SessionNoteType } from '../../../../../__generated__/graphql';
import { ApiValidationError, mapApiFieldValidationErrors } from '../../../../../components/Form';
import { ApiUnknownError } from '../../../../../components/Form/mapApiFieldValidationErrors';
import { useWorkListContext } from '../../../../../context/workLists';
import { useSlideInContext } from '../context/SlideInProvider';
import NoteForm from './NoteForm';
import { SESSION_NOTES_QUERY } from '../../ResultsGrid/HistoryTable';


const ADD_SESSION_NOTE_MUTATION = gql(/* GraphQL */`
    mutation AddSessionNote($sessionId: UUID! $type: SessionNoteType!, $body: String!) {
        addSessionNote(input: { sessionId: $sessionId, type: $type, body: $body }) {
            __typename
            errors {
                __typename
                ... on UnknownError {
                    message
                }
                ... on ValidationError {
                    message
                    errors {
                        fieldName
                        messages
                    }
                }
            }
        }
    }
`);

export default function AddNote() {
    const [addSessionNoteMutation] = useMutation<AddSessionNoteMutation, AddSessionNoteMutationVariables>(ADD_SESSION_NOTE_MUTATION);
    const { toggleDrawer } = useSlideInContext();
    const { session } = useWorkListContext();

    return (
        <div style={{ color: "#fff" }}>
            <NoteForm 
                onCancel={() => toggleDrawer()} 
                onSubmit={async values => {
                    const response = await addSessionNoteMutation({
                        variables: {
                            sessionId: session?.id,
                            type: SessionNoteType.Default,
                            body: values.note
                        },
                        refetchQueries: [
                            {
                                query: SESSION_NOTES_QUERY,
                                variables: {
                                    sessionId: session?.id
                                }
                            }
                        ]
                    })

                    if (response.data?.addSessionNote.errors) {
                        const validationError = response.data.addSessionNote.errors
                            .find(e => e.__typename === "ValidationError") as ApiValidationError;

                        if (validationError.errors) {
                            const fieldErrors = validationError 
                            ? mapApiFieldValidationErrors(validationError)
                            : null;
                    
                            return fieldErrors;
                        }
                        
                        const unknownError = response.data.addSessionNote.errors
                            .find(e => e.__typename === 'UnknownError') as ApiUnknownError;

                        if (unknownError)
                        {
                            return { [FORM_ERROR]: unknownError.message }
                        }
                    }

                    toggleDrawer();
                }} />
        </div>
    )
}
