/**
 * Get the parsed payload data from a JWT (JSON Web token)
 * @param encodedToken JWT string
 * @returns T | null (json object representing the parsed JWT payload object)
 */
export function parseJwt<T>(encodedToken: string): T | null {
    try {
        var payloadData = encodedToken.split(".")[1];
        return JSON.parse(atob(payloadData));
    }
    catch (e) {
        return null
    }
}

/**
 * Returns normalized (contains specific properties) object representing a 
 * decoded JWT access token
 * 
 * @param decodedToken Intended to be a JWT payload data token object
 * @returns object including access_token, token_type, and expires_in
 */
export function normalizeAuthTokenObject(decodedToken: any) {
    const { access_token, token_type, expires_in } = decodedToken;
    return { access_token, token_type, expires_in } as const;
}