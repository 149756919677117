
function validatePassword(p: string) {
    if (p.length < 8) {
        return "Your password must be at least 8 characters"
    }
    if (p.search(/[a-z]/i) < 0) {
        return "Your password must contain at least one letter."
    }
    if (p.search(/[0-9]/) < 0) {
        return "Your password must contain at least one digit."
    }
    if (p.search(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/) < 0) {
        return "Your password must contain a special character."
    }
    return false;
}

export { validatePassword }