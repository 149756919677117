import { Box, CircularProgress, Container, Fade } from "@mui/material";
import { Navigate, Route, Routes, useNavigate } from "react-router"
import { SlideInContextProvider } from "../../Admin/components/SlideIn/context/SlideInProvider";
import SlideIn from "../../Admin/components/SlideIn";
import AppBar from "../../Admin/components/AppBar";
import { StudentLinkPage, StudentManagePage } from ".";
import { SelectedStudentProvider, useSelectedStudent } from "./hooks";
import { StudentDataType } from "./hooks/useSelectedStudent";
import { StudentsPage } from "./StudentsPage";

const StudentManagementRoutes = () => {

    const navigate = useNavigate();

    const { setSelectedStudent } = useSelectedStudent();

    const handleSelectedStudent = (student: StudentDataType) => {
        setSelectedStudent(student);
        navigate(`details/${student?.id}`);
    }

    return (
        <Routes>
            <Route index element={(
                <StudentsPage 
                    onBack={() => navigate('..')}
                    onSelectedStudent={handleSelectedStudent} />
            )} />
            <Route path="details/:sid" element={(
                <StudentManagePage 
                    onLink={(id) => navigate(`link/${id}`)} 
                    onBack={() => navigate(-1)}/>
            )} />
            <Route path="link/:sid" element={<StudentLinkPage onBack={() => navigate(-1)} />} />
            <Route path="*" element={<Navigate to="." replace />} />
        </Routes>
    )
}


export const StudentIndexPage = () => {
    
    const loading = false;

    return (
        <Fade in={true}>
            <Box component="div">
                <SlideInContextProvider>
                <div>
                    <SlideIn />
                    <AppBar />
                </div>
                <Container maxWidth="xl">
                    <Box
                        component="div"
                        sx={{ paddingTop: "40px", paddingBottom: "32px" }}
                    >
                    {loading ? (
                        <div
                        style={{
                            width: "100%",
                            height: "50vh",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                        >
                        <CircularProgress />
                        </div>
                    ) : (
                        <SelectedStudentProvider>
                            <StudentManagementRoutes />
                        </SelectedStudentProvider>
                    )}
                    </Box>
                </Container>
                </SlideInContextProvider>
            </Box>
        </Fade>
    )
}