import { z } from "zod";
import InputField from "../../../../../components/Form/InputField";
import { FieldProps } from "react-final-form";
import { FormSubmitCallback } from "../../../../../components/Form";
import { FormBase } from "../../../../../components/Form/FormBase";

const EMAIL_FORM_FIELDS: FieldProps<string, any>[] =[
    {
        name: 'email',
        label: 'Email',
        render: InputField,
        required: true,
        fullWidth: true,
        grid: {
            xs: 12,
        },
    }
];

const emailValidationSchema = z.object({
    email: z.string().email("Invalid email")
});

export type EmailFormSchema = z.infer<typeof emailValidationSchema>;

type EmailFormState = {
    fields: FieldProps<string, any>[]
}

export type EmailFormProps = {
    onSubmit: (values: EmailFormSchema) => Promise<ReturnType<FormSubmitCallback>>
}

export default function EmailForm({ onSubmit }: EmailFormProps) {
    return FormBase<EmailFormSchema>({
        fields: EMAIL_FORM_FIELDS,
        initialValues: {},
        validationSchema: emailValidationSchema,
        buttonSubmitLabel: "Submit",
        onSubmit: onSubmit,
        onCancel: null
    });
}

