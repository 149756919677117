import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "& .results-grid": {
      backgroundColor: "#F03F3F",
      marginBottom: "24px",
    },
    // '& .results-grid--Severe': {
    //     backgroundColor: "#FECACA",
    //     '& :hover': {
    //         backgroundColor: "#FECACA",
    //     }
    // },
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#3f51b5",
      color: "#fff",
    },
    "& .MuiDataGrid-root": {
      background: "#fff",
      border: "none",
      boxShadow: (props: any) =>
        (props as IStyledGridProps).disableBoxShadow
          ? ""
          : "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-sortIcon": {
      color: "#fff",
    },
    "& .MuiDataGrid-menuIconButton": {
      color: "#fff",
    },
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
    {
        outline: "none",
    },
  },
});

interface IStyledGridProps extends DataGridProps {
  disableBoxShadow?: boolean;
}

const DataGridStyled = (props: IStyledGridProps): JSX.Element => {
  const { disableBoxShadow, ...rest } = props;
  const classes = useStyles({ disableBoxShadow });
  return (
    <Box component="div" className={classes.root}>
      <DataGrid
        {...rest}
        sx={{
          ".MuiDataGrid-columnHeader": {
            backgroundColor: "#253D93",
            color: "#fff",
          },
          ".MuiDataGrid-columnHeaders": {
            borderRadius: 0,
          },
          ".MuiDataGrid-root": {
            boxShadow: "none",
          },
        }}
      />
    </Box>
  );
};

export default DataGridStyled;
