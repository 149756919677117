import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, IconButton } from "@mui/material";

// Use on tables that are refetched when data is mutated and loading state doesn't need to be flipped
export default function TableRemoveButton({
  id,
  disabled = false,
  removing = false,
  onRemove,
  onDisabled
}: {
  id: string;
  disabled?: boolean,
  removing?: boolean,
  onRemove: (id: string) => void;
  onDisabled?: () => void
}) {
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);

  const handleOnRemove = () => {
    onRemove(id);
    // setIsRemoveLoading(true);
  };

  const handleDisabled = () => {
    if (onDisabled) onDisabled();
  }

  return removing || isRemoveLoading 
    ? <CircularProgress size={24} color="secondary" /> 
    : (
      <IconButton onClick={disabled ? handleDisabled : handleOnRemove}>
        <CloseIcon
          style={{
            backgroundColor: disabled ? "#BBB" : "#8E1C3B",
            borderRadius: "100%",
            color: "#FFF",
          }}
        />
      </IconButton>
    );
}
