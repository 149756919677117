import React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FilterByTypes } from "../../hooks/useFilterBy";

export default function FilterBy({
  value,
  handleChange,
}: {
  value: string;
  handleChange: (value: string) => void;
}) {
  return (
    <Box component="span">
      <FormControl fullWidth>
        <Select
          id="manage-fiilter-by-select"
          value={value}
          defaultValue={FilterByTypes.ALL_RECORDS}
          onChange={(event) => handleChange(event.target.value as string)}
          sx={{ height: "40px" }}
        >
          {Object.values(FilterByTypes).map((type) => (
            <MenuItem key={type} value={type}>{type}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
