import { format } from "date-fns";
import { Box, Button, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export interface LinkItemsHeaderProps {
  entityId: string, 
  startDate: Date,
  endDate?: Date | null,
  expired?: boolean | null,
  onExpire?: (id: string) => void
}

export default function LinkItemsHeader({ entityId, startDate, endDate, expired = false, onExpire}: LinkItemsHeaderProps) {

  return (
    <Grid item container style={{ paddingTop: 16, paddingBottom: 32 }}>
      <Grid item xs={12}>
        <Box
          component="div"
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Box component={"div"} style={{ marginRight: 32, minWidth: "140px" }}>
            <Typography color="secondary" sx={{ fontSize: "14px" }}>
              Start Date
            </Typography>
            <Typography sx={{ fontSize: "18px" }}>
              {startDate
                ? format(new Date(startDate), "MM/dd/yyyy")
                : "n/a"}
            </Typography>
          </Box>
          <Box component={"div"} style={{ marginRight: 32, minWidth: "140px" }}>
            <Typography color="secondary" sx={{ fontSize: "14px" }}>
              End Date
            </Typography>
            <Typography sx={{ fontSize: "18px" }}>
              {endDate
                ? format(new Date(endDate), "MM/dd/yyyy")
                : "n/a"}
            </Typography>
          </Box>

          <Button
            sx={{ color: expired ? 'primary' : "#8E1C3B" }}
            onClick={() => onExpire && onExpire(entityId ?? '')}
            startIcon={
              expired 
                ? (
                  <CheckCircleIcon />
                )
                : (
                  <CloseIcon
                    style={{
                      backgroundColor: "#8E1C3B",
                      borderRadius: "100%",
                      color: "white",
                    }}
                  />
                )
            }
          >
            { expired ? "Reactivate User" : "Expire User" }
          </Button>
          
        </Box>
      </Grid>
    </Grid>
  );
}