import React from "react";
import { Box, Grid, Typography, Paper, Button, TextField, Fade, Collapse } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const passwordTestRegex = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/);

const PasswordReset = () => {
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState('');
    const [submitting, setSubmmiting] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [token, setToken] = React.useState('');
    const { email } = useParams<any>();

    const [newPassword, setNewPassword] = React.useState('')
    const [confirmNewPassword, setConfirmNewPassword] = React.useState('')

    const navigate = useNavigate();

    const handleChange = (name: string, value: string) => {
        if (showError) {
            setShowError(false);
        }

        if (name === "newPassword") {
            setNewPassword(value)
        } else {
            setConfirmNewPassword(value)
        }
    }

    React.useEffect(() => {
        if (newPassword === confirmNewPassword) {
            if (!Boolean(passwordTestRegex.test(newPassword) && passwordTestRegex.test(confirmNewPassword))) {
                setError('Your password must be at least 6 characters long, contain at least one number, one special character, and have a mixture of uppercase and lowercase letters.')
                setShowError(true);
            }
        }
    }, [newPassword, confirmNewPassword])

    const handleSend = async () => {
        setSubmmiting(true)
        var data = JSON.stringify({
            "email": email,
            "password": `${newPassword}`,
            "confirmPassword": `${confirmNewPassword}`,
            "token": `${token}`
        });
        var config = {
            method: 'post',
            url: `${window.__env.API_URL}/api/oauth/reset-password`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //@ts-ignore
        await axios(config).then((res: any) => {
            setSuccess(true);
        })
            .catch(function (error) {
                if (error.response) {
                    //CHANING TO 
                    setError("This link is invalid. Please request a new link via forgot password");
                } else if (error.request) {
                    // The request was made but no response was received
                    setError("Connection refused. Please try again later");
                } else {
                    // Something happened in setting up the request that triggered an Error
                    setError("Connection refused. Please try again later");
                }
                setSubmmiting(false);
                setShowError(true)
            });
    };

    React.useEffect(() => {
        var _token;
        try {
            _token = window.location.href.toString().split("token=")[1]
            setToken(_token)
        } catch (e) {
            navigate("/forgot-password")
        }
    }, [])


    const renderContent = () => {
        if (!success) {
            return <Grid item xs={12}>
                <Grid container spacing={2} style={{ marginTop: "24px", marginBottom: "16px", maxWidth: "400px", margin: "auto" }}>
                    <Grid item xs={12}>
                        <TextField label="New Password" type="password"
                            value={newPassword}
                            onChange={(e) => handleChange("newPassword", e.target.value)}
                            fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Confirm Password" type="password"
                            value={confirmNewPassword}
                            onChange={(e) => handleChange("confirmNewPassword", e.target.value)}
                            fullWidth />
                    </Grid>
                </Grid>
            </Grid>
        } else {
            return <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: 400 }}>
                    Success!
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 400 }}>
                    We successfully updated your password.
                </Typography>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "24px" }}>
                    <Button variant="contained" sx={{ backgroundColor: "#C4C4C4" }} onClick={() => navigate('/login')}>
                        Return to Login Page
                    </Button>
                </div>
            </Grid>
        }
    }
    return <div style={{ height: "100vh", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Fade in={true} timeout={400}>
            <Box component={Paper} sx={{ width: "550px", height: "auto", padding: "24px" }}>
                <Grid container spacing={2} sx={{ display: "flex", alignContent: "space-between", height: "100%" }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" color="secondary" sx={{ fontWeight: "bold" }}>
                            Enter your new password
                        </Typography>
                    </Grid>
                    {renderContent()}
                    <Grid item xs={12}>
                        <Collapse in={showError}>
                            <Typography color="error" sx={{ marginTop: "8px", marginBottom: "8px" }}>{error}</Typography>
                        </Collapse>
                    </Grid>
                </Grid>
                <Collapse in={!success}>
                    <Grid container spacing={2} sx={{ paddingTop: "8px" }}>
                        <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                            <Button variant="contained" onClick={() => navigate('/login')} sx={{ backgroundColor: "#C4C4C4" }}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                            {submitting ? (
                                <LoadingButton
                                    loading={submitting}
                                    variant="contained"
                                >
                                    Submit
                                </LoadingButton>
                            ) : (
                                <Button variant="contained" disabled={Boolean(newPassword.length === 0) || newPassword !== confirmNewPassword || showError} onClick={() => handleSend()}>
                                    Send
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Collapse>
            </Box>
        </Fade>
    </div>
}

export default PasswordReset;