import { Box, Grid, IconButton, Typography } from "@mui/material"
import { createTheme, Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const defaultTheme = createTheme();

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        padding: "8px 16px",
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        border: "none",
      },
    }),
  { defaultTheme }
);

export type SimpleToolbarProps = {
    title?: string,
    onBack?: () => void
    rightSlot?: () => React.ReactNode
}

export default function SimpleToolbar({
    title,
    onBack,
    rightSlot
}: SimpleToolbarProps) {
    const classes = useStyles();
    return (
        <Grid container className={classes.root}>
            <Grid item xs={8}>
                <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
                    {onBack && (
                        <IconButton onClick={onBack} sx={{ marginRight: "8px" }}>
                            <ChevronLeftIcon />
                        </IconButton>
                    )}
                    {title && (
                        <Typography variant="h4" sx={{ textAlign: "left" }}>
                            {title}
                        </Typography>
                    )}
                </Box>
            </Grid>
            <Grid item xs={4}>
                <>
                    { rightSlot && rightSlot() }
                </>
            </Grid>
        </Grid>
    )
}