import React, { useEffect, useState } from "react";

import { Card, Grid, Paper } from "@mui/material";
import { format, parseISO } from "date-fns";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { ApiValidationError, FormSubmitCallback, mapApiFieldValidationErrors } from "../../../components/Form";
import { gql } from "../../../__generated__";

import StudentForm, { CustomField, StudentFormCustomFieldsSchema, StudentFormSchema } from './StudentForm';
import { dateOnlyISO8601 } from "../../../library/date";
import { CustomFieldInput, EditStudentMutation, EditStudentMutationVariables } from "../../../__generated__/graphql";
import StudentTableLinkPreview from "./StudentTableLinkPreview";
import LinkItemsHeader from "../components/ManageDetails/components/LinkItems/LinkItemsHeader";
import { useSelectedStudent } from "./hooks";
import Header from "../components/Header";
import useStudentPersonsAssignment from "./hooks/useStudentPersonsAssignment";
import { useManageModal } from "../components/ManageModal";
import useExpireStudent from "./hooks/useExpireStudent";
import usePersonStore from "../../Admin/personStore";


const STUDENT_QUERY = gql(`
  query StudentQuery($studentId: UUID!) {
    student(id: $studentId) {
      __typename
      id 
      locationId
      firstName
      middleName
      lastName
      dob
      postalCode
      parentEmail
      parentPhone
      startDate
      endDate
      expired
      references
      studentCustomFields {
        value
        customField {
          id
        }
      }
    },
    locations {
      __typename
      id
      customFields {
        __typename
        id
        type
        name
      }
    }
  }
`);

const EDIT_STUDENT_MUTATION = gql(`
  mutation EditStudent($input: EditStudentInput!) {
    editStudent(input: $input) {
      __typename
      student {
        id 
      }
      errors {
        __typename
        ... on UnknownError {
          message
        }
        ... on ValidationError {
          errors {
            fieldName
            messages
          }
        },
        ... on EntityNotFoundError {
          message
        }
      }
    }
  }
`);

interface StudentDetailsPageProps {
  onBack: () => void,
  onLink: (id: string) => void,
}

type Params = {
  sid: string
}

type PageState = {
  customFields: CustomField[],
  studentCustomFields: StudentFormCustomFieldsSchema
}

export default function StudentDetailsPage({
  onBack,
  onLink,
}: StudentDetailsPageProps) {

  const params = useParams<Params>();

  const { openModal, closeModal } = useManageModal();

  const { student } = useSelectedStudent(params.sid);
  const defaultLocation = usePersonStore(state => state.company.defaultLocation)
  const { save } = useStudentPersonsAssignment(params.sid ?? '');

  const { expireStudent } = useExpireStudent();

  const { loading, data } = useQuery(STUDENT_QUERY, {
    variables: {
      studentId: params.sid
    }
  });

  const [editPerson] = useMutation<EditStudentMutation, EditStudentMutationVariables>(EDIT_STUDENT_MUTATION);

  const [pageState, setPageState] = useState<PageState>({
    customFields: [],
    studentCustomFields: null
  });

  const [removeRowId, setRemoveRowId] = useState<string | null>(null);
  useEffect(() => {
    const _location = data?.locations.find((location) => location.id === defaultLocation?.id)
    const customFields: CustomField[] = _location?.customFields.map(field => ({
      id: field.id,
      name: field.name ?? '',
      type: field.type ?? '',
    })) ?? []
    // const customFields: CustomField[] = data?.locations[0].customFields.map(field => ({
    //   id: field.id,
    //   name: field.name ?? '',
    //   type: field.type ?? '',
    // })) ?? []

    const studentCustomFields = data?.student.studentCustomFields
      .filter(x => x)
      // @ts-ignore
      .reduce<StudentFormCustomFieldsSchema>((accumulator, studentCustom) => {
        return {
          ...accumulator,
          [studentCustom.customField?.id]: {
            id: studentCustom.customField?.id,
            value: studentCustom.value
          }
        }
      }, {});

    setPageState(prevState => ({
      ...prevState,
      customFields,
      studentCustomFields
    }));
  }, [loading, data]);

  const handleEditPersonSubmission: FormSubmitCallback<StudentFormSchema> = async (values):
    Promise<ReturnType<FormSubmitCallback>> => {


    const customFields = Object.keys(values.customFields ?? {})
      .filter(key => values?.customFields?.[key] ?? null)
      .map(key => {

        // Shouldn't be null undefined and nulls are filtered out
        const customField = values.customFields![key];
        return {
          id: customField.id,
          value: customField.value
        } as CustomFieldInput
      });

    const input = {
      id: params.sid,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      dob: values.dob,
      postalCode: values.postalCode,
      parentEmail: values.parentEmail,
      parentPhone: values.parentPhone,
      customFields: customFields
    }

    const response = await editPerson({ variables: { input } })

    if (response.data?.editStudent.errors) {

      const validationError = response.data.editStudent.errors
        .find(e => e.__typename === "ValidationError") as ApiValidationError;

      const fieldErrors = validationError
        ? mapApiFieldValidationErrors(validationError)
        : null;

      return fieldErrors;
    }

    console.log('Submitting Student edit details', values, customFields);
    return;
  }

  const handleUnassigment = async (id: string) => {
    console.log('STUDENT References', student?.references)
    if ((student?.references ?? 0) <= 1) {
      openModal({ type: 'NOT_ENOUGH_REFERENCES' })
      return;
    }

    setRemoveRowId(id);

    openModal({
      type: 'REMOVE_ASSIGNMENT',
      confirmButtonType: 'WARNING',
      confirmButtonLabel: 'Yes',
      onConfirm: async () => {
        openModal(state => ({
          ...state,
          status: 'SAVING'
        }));

        const unassign = [id];
        await save([], unassign);

        closeModal();
        setRemoveRowId(null);
      },
      onCancel: () => {
        setRemoveRowId(null);
      }
    })
  }

  const handleExpire = async () => {
    if (!student?.expired) {
      openModal({
        type: 'EXPIRE_STUDENT',
        confirmButtonType: 'WARNING',
        confirmButtonLabel: 'Expire',
        onConfirm: async () => {
          openModal(state => ({
            ...state,
            status: 'SAVING'
          }))

          await expireStudent(student?.id)

          closeModal();
        }
      })
    } else {
      openModal({
        type: 'REACTIVATE',
      })
    }
  }

  return (
    <Card
      elevation={4}
      style={{
        width: "100%",
        display: "flex",
        padding: "16px",
        height: "auto",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Header
            onBack={onBack}
            title={student
              ? `Details for ${student.firstName} ${student.lastName}`
              : 'Loading...'}
          />
          <StudentForm
            readonly={student?.expired}
            customFields={pageState.customFields}
            initialValues={{
              firstName: student?.firstName ?? '',
              middleName: student?.middleName,
              lastName: student?.lastName ?? '',
              postalCode: student?.postalCode ?? '',
              dob: student?.dob
                ? format(parseISO(dateOnlyISO8601(student?.dob)), 'yyyy-MM-dd')
                : '',
              parentEmail: student?.parentEmail,
              parentPhone: student?.parentPhone,
              customFields: pageState.studentCustomFields
            }}
            onSubmit={handleEditPersonSubmission}
            onCancel={onBack}
          />
        </Grid>
        <Grid item xs={6}>
          <LinkItemsHeader
            entityId={student?.id ?? ''}
            startDate={student?.startDate}
            endDate={student?.endDate}
            expired={student?.expired}
            onExpire={handleExpire} />
          {!student?.expired && (
            <Paper component="div" variant="outlined">
              <StudentTableLinkPreview
                studentId={params.sid ?? ''}
                removeRowId={removeRowId}
                onLink={onLink}
                onRemove={handleUnassigment}
                onDisabled={(reason) => {
                  openModal({ type: reason })
                }} />
            </Paper>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}
