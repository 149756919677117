import { gql } from "@apollo/client";

export const GET_PEOPLE = gql`
query GetPeople {
    persons {
      id
      firstName
      middleName
      lastName
      jobTitle
      email
      startDate
      endDate
      status
      createdAt
      expired
      role {
        name
        normalizedName
      }
      company {
        id
        name
      }
      students {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            firstName
            lastName
            dob
            createdAt
            sessions {
              id
              createdAt
              finishedAt
            }
            lastSession {
              finishedAt
            }
          }
        }
        totalCount
      }
    }
  }
`;