import { gql } from "@apollo/client";

export const ADD_STUDENT = gql(/* GraphQL */ `
  mutation AddStudent($input: AddStudentInput!) {
    addStudent(
      input: $input
    ) {
      __typename
      student {
        id
      }
      errors {
        ... on ValidationError {
          message
          errors {
            fieldName
            messages
          }
        }
      }
    }
  }
`);
