import React, { useContext, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

import { WORKLISTS_BY_PERSON_QUERY } from "../../features/graphql/query/worklist/getWorklist";
import usePersonAuthStore from "../../pages/Admin/personAuthStore";
import { Session } from "../../__generated__/graphql";

export interface WorkList {
    id: string;
    status: string;
    notes: Notes[];
    studentId: string;
    createdAt: string;
    name: string;
    report: { id: string }
    session: {
        tags: {
            ace: [string],
            pce: [string]
        }
    }
}

export interface Notes {
    data: string;
    id: string;
    createdAt: string;
    body: string;
    type: string;
    username: string;
}

export type WorkListContextContext = {
    workLists: WorkList[] | null;
    getWorkLists: () => void;
    loading: boolean;
    activeWorkListId: string | null;
    setActiveWorkListId: (id: string | null) => void;
    username: null | string;
    personId: string;
    session: Session | null,
    setSession: (session: Session) => void
}

const noop = () => { };

const WorkListContext = React.createContext<WorkListContextContext>({
    personId: "",
    username: null,
    loading: false,
    workLists: null,
    activeWorkListId: null,
    getWorkLists: noop,
    setActiveWorkListId: noop,
    session: null,
    setSession: noop,
});

WorkListContext.displayName = 'WorkListContext';

const useWorkListContext = () => useContext(WorkListContext)

const WorkListContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [fetchWorkLists, { loading, data }] = useLazyQuery(
        WORKLISTS_BY_PERSON_QUERY,
        {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "cache-and-network"
        });

    const [id, setId] = useState("");
    const [workLists, setWorkLists] = useState<any[] | null>(null);
    const [username, setUsername] = useState<string | null>(null);
    const [activeWorkListId, setActiveWorkListId] = useState<string | null>(null)
    const [session, setSession] = useState<Session | null>(null);

    const accessToken = usePersonAuthStore(state => state.access_token);

    const handleGetWorkLists = async () => {
        if (accessToken) {
            await fetchWorkLists()
        }
    };

    const handleSetActiveWorkListId = (id: string | null) => {
        setActiveWorkListId(id)
    }
    useEffect(() => {
        console.log('the person id in the worklist provider: ', id)
    }, [id])
    useEffect(() => {
        console.log('worklist query data: ', data)
    }, [data])
    useEffect(() => {
        if (data?.me?.firstName && data?.me?.lastName) {
            setId(data.me.id);
            setUsername(`${data?.me?.firstName} ${data?.me?.lastName}`)
        }
        const _workLists = data?.me?.workLists;
        if (_workLists && _workLists?.length > 0) {
            setWorkLists(_workLists.map((wl: any) => {
                return {
                    status: wl.status,
                    id: wl.id,
                    notes: wl.notes.length ? wl.notes.map((n: any) => ({
                        ...n,
                        username: wl.person && wl.person.firstName && wl.person.lastName ? `${wl.person.firstName} ${wl.person.lastName}` : "--"
                    })) : [],
                    session: wl?.session,
                    report: wl?.session?.report,
                    createdAt: wl.createdAt,
                    studentId: wl?.session?.student?.id
                }
            }))

        }
    }, [data])
    return (
        <WorkListContext.Provider value={{
            username,
            personId: id,
            loading,
            workLists: workLists,
            activeWorkListId: activeWorkListId,
            getWorkLists: handleGetWorkLists,
            setActiveWorkListId: handleSetActiveWorkListId,
            session,
            setSession
        }}>
            {children}
        </WorkListContext.Provider>
    );
};

export { useWorkListContext, WorkListContextProvider };
