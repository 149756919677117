import gql from "graphql-tag";

export const GET_STUDENTS_NO_PAGINATION = gql`
  query GetPerson {
    students {
      edges {
        node {
          id
          firstName
          lastName
          dob
        }
      }
    }
  }
`;
