import React, { useEffect } from "react";
import { Box, CircularProgress, Container, Fade } from "@mui/material";


import SlideIn from "../../Admin/components/SlideIn";
import {
  SlideInContextProvider,
} from "../../Admin/components/SlideIn/context/SlideInProvider";
import AppBar from "../../Admin/components/AppBar";
import { useUserData } from "../hooks/useUserData";

import { useQuery } from "@apollo/client";
import { PEOPLE_FOR_LINK_QUERY } from "../../../features/graphql/query/person/getPeopleForLink";
import { useSelectedPerson } from "./SelectedPersonProvider";
import PeoplePage from "./PeoplePage";
import PersonManagePage from "./PersonManagePage";
import { PersonTableLink } from ".";
import { Navigate, Route, Routes, useNavigate } from "react-router";

export const PersonIndexPage = () => {

  const { loading } = useUserData();

  const { loading: loadingPeople, fetchMore: fetchMorePeople } =
    useQuery(PEOPLE_FOR_LINK_QUERY);

  const { setSelectedPerson } = useSelectedPerson(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchMorePeople({});
  }, []);

  const handleGoBack = () => {
    setSelectedPerson(null);
    navigate(-1);
  }

  const PersonManagementRoutes = () => {

    return (
      <Routes>
        <Route index element={<PeoplePage onBack={handleGoBack} />} />
        <Route path="details/:pid" element={(
          <PersonManagePage 
            onLink={(id) => navigate(`link/${id}`)}
            onBack={handleGoBack}/>
        )} />
        <Route path={`link/:pid`} element={<PersonTableLink onBack={handleGoBack} />} />
        <Route path="*" element={<Navigate to="." replace />} />
      </Routes>
    )
  }

  return (
    <Fade in={true}>
      <Box component="div">
        <SlideInContextProvider>
          <div>
            <SlideIn />
            <AppBar />
          </div>
          <Container maxWidth="xl">
            <Box
              component="div"
              sx={{ paddingTop: "40px", paddingBottom: "32px" }}
            >
              {loading ? (
                <div
                  style={{
                    width: "100%",
                    height: "50vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <PersonManagementRoutes />
              )}
            </Box>
          </Container>
        </SlideInContextProvider>
      </Box>
    </Fade>
  );
};
