import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from 'react-router';
import { storage } from "../../constants/sessionStorage";
import {Avatar, Student} from "../../__generated__/graphql";

const MALE = "male";
const FEMALE = "female";

const girlId = "4C5A8DB8-EF10-44A7-86EE-CFC34AB06425";
const boyId = "4B5A8DB8-EF10-44A7-86EE-CFC34AB06425"

const GENDER_OPTIONS = {
    [MALE]: boyId,
    [FEMALE]: girlId
}

type Gender = keyof typeof GENDER_OPTIONS;

export interface PlayerAvatar {
    avatar?: Avatar | null
    gender?: string | null
}

export type PlayerData = PlayerAvatar & Student;

export type PlayerLobbyContent = {
    playerData: any | null;
    changePlayer: (player: any) => void;
    updatePlayer: (player: PlayerData | null | undefined) => void,
    changeGender: (gender: Gender) => void;
    gender?: string | null;
}

const PlayerLobbyContext = React.createContext<PlayerLobbyContent>({
    playerData: null,
    gender: null,
    updatePlayer: (player: PlayerData | null | undefined) => { },
    changePlayer: () => { },
    changeGender: () => { }
});

PlayerLobbyContext.displayName = 'PlayerLobbyContext';

const usePlayerLobby = () => useContext(PlayerLobbyContext)

interface IProps {
    children: React.ReactNode
}

const PlayerDataProvider = (props: IProps) => {
    const navigate = useNavigate();

    const { children } = props;
    const [playerData, setPlayerData] = useState<PlayerData | null>(null);
    const [gender, setPlayerGender] = useState<string | null>(null)

    const savePlayerData = (data: PlayerData) => 
        window.sessionStorage.setItem(storage.names.PLAYER_DATA, JSON.stringify(data));

    const handleUpdatePlayer = (player: PlayerData | null | undefined) => {
        if (!player) return;

        setPlayerData((state) => {
            const newState = {
                ...state,
                ...player
            }
            
            return newState;
        });
    }

    const handleChangePlayer = (player: any) => {
        const avatar = player?.avatars?.length > 0 ? player?.avatars[0] : null;
        const data = {
            ...player,
            avatar
        }

        setPlayerData(data);

        setPlayerGender(avatar?.bodyId);
        // navigate("/survey/lobby", { replace: true })
    }
    const handleChangeGender = (gender: Gender) => {
        if (!gender) {
            setPlayerGender(null)
        } else {

            setPlayerGender(GENDER_OPTIONS[(gender)])

            const _playerData = {
                ...playerData, 
                avatar: {
                    ...playerData?.avatar,
                    bodyId: GENDER_OPTIONS[gender] 
                }
            };
            
            setPlayerData(_playerData as any);
            // window.sessionStorage.setItem(storage.names.PLAYER_DATA, JSON.stringify(_playerData))
        }
    };

    useEffect(() => {
        const _playerData = window.sessionStorage.getItem(storage.names.PLAYER_DATA)
        if (_playerData) {
            try {
                const _data = JSON.parse(_playerData)
                setPlayerData(_data);

                if (_data.avatar?.bodyId) {
                    setPlayerGender(_data.avatar?.bodyId)
                }
            } catch (e) {
                window.sessionStorage.removeItem(storage.names.PLAYER_DATA)
                navigate("/login", { replace: true })
            }
        }
    }, [])

    useEffect(() => {
        if (!playerData) return;
        savePlayerData(playerData);
    }, [playerData]);

    return (
        <PlayerLobbyContext.Provider value={{
            playerData,
            gender: gender && gender.toUpperCase() === boyId ? MALE : FEMALE,
            changePlayer: handleChangePlayer,
            updatePlayer: handleUpdatePlayer,
            changeGender: handleChangeGender,
        }} >
            {children}
        </PlayerLobbyContext.Provider>
    );
};

export { usePlayerLobby, PlayerDataProvider, boyId, girlId, MALE, FEMALE };
