import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { PEOPLE_FOR_LINK__PERSON_FRAGMENT } from "../../../features/graphql/query/person/fragments/peopleForLink__person";
import { PERSON_FOR_LINK_QUERY } from "../../../features/graphql/query/person/getPersonForLink";
import { getFragmentData } from "../../../__generated__";
import { PeopleForLink__PersonFragment } from "../../../__generated__/graphql";

export type PersonDataType = PeopleForLink__PersonFragment | null;

export interface SelectedPersonContextType {
    person: PersonDataType,
    setSelectedPerson: (person: PersonDataType) => void,
}

export const SelectedPersonContext = React.createContext<SelectedPersonContextType | null>(null);

SelectedPersonContext.displayName = "SelectedPersonContext";


export interface SelectedPersonProviderProps {
    children: React.ReactNode
}

export const SelectedPersonProvider = ({ children }: SelectedPersonProviderProps) => {
    const [person, setSelectedPerson] = useState<PersonDataType>(null);

    const memorizedValue = useMemo(() => ({
        person, setSelectedPerson
    }), [person, setSelectedPerson]);

    return (
        <SelectedPersonContext.Provider value={memorizedValue}>
            { children }
        </SelectedPersonContext.Provider>
    )
}

export const useSelectPersonContext = () => {
    const context = useContext(SelectedPersonContext);

    if (!context) 
    throw new Error('Call made outside provider. You must use useSelectPersonContext within the SelectedPersonProvider provider.');

    

    return context;
}

interface SelectedPerson {
    loading: boolean,
    person: PersonDataType,
    setSelectedPerson: (person: PersonDataType | null) => void,
    fetchSelectedPerson: (id: string) => void
}

export const useSelectedPerson = (id?: string | null): SelectedPerson => {
    const { person, setSelectedPerson } = useSelectPersonContext();
    const [ fetch, {data, loading, fetchMore}] = useLazyQuery(PERSON_FOR_LINK_QUERY);

    const fetchSelectedPerson = (id: string) => {
        fetch({
            variables: {
                personId: id
            }
        })
    }

    useEffect(() => {
        if (!data) return;
        console.log('Data for person updated', data, person);
    }, [data]);

    useEffect(() => {
        if (!id || person) return;
        fetchSelectedPerson(id);
    }, [id, person]);

    useEffect(() => {
        if (loading || !data) return;
        const fragment = getFragmentData(PEOPLE_FOR_LINK__PERSON_FRAGMENT, data.person);
        setSelectedPerson(fragment);
    }, [loading, data])


    return { loading, person, setSelectedPerson, fetchSelectedPerson };
}