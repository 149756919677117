import gql from "graphql-tag";

export const STUDENTS_WITH_LOCATION_DETAILS_QUERY = gql(/* GraphQL */ `
  query StudentsWithLocationDetails(
    $query: String
    $first: Int
    $after: String
  ) {
    students(query: $query, status: ACTIVE, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          dob
          parentEmail,
          firstName
          lastName
          gradeLevel
          studentCustomFields {
            customField {
              id
              type
              name
            }
            value
          }
          persons {
            firstName
            lastName
            id
            role {
              name
              normalizedName
            }
          }
          lastSession {
            id
            checkpoint {
              current {
                name
              }
            }
            status
            summary {
              id
              livingSituation
              numberOfSiblings
              peopleCountedOn
              livesMainlyWith
            }
          }
          avatars {
            id
            bodyId
            bodyColor
            eyeColor
            hairColor
            helperId
            shirtColor
            pantsColor
            shoesColor
          }
        }
      }
      totalCount
    }
    location {
      customFields {
        id
        position
        type
        name
        description
      }
    }
  }
`);
