// src/hooks/useInterval.ts
import { useEffect, useRef } from "react";
function useInterval(callback, ms) {
  const cachedCallback = useRef(null);
  useEffect(() => {
    cachedCallback.current = callback;
  });
  useEffect(() => {
    function tick() {
      var _a;
      (_a = cachedCallback == null ? void 0 : cachedCallback.current) == null ? void 0 : _a.call(cachedCallback, null);
    }
    var internval = setInterval(tick, ms);
    return () => clearInterval(internval);
  }, [ms]);
}
export {
  useInterval
};
