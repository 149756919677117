import React from "react";
import { Box, Grid, Typography, Paper, Button, TextField, Fade, Collapse } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import { useNavigate } from "react-router";

const ForgotPassword = () => {
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState('');
    const [submitting, setSubmitting] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [showError, setShowError] = React.useState(false)
    const navigate = useNavigate();

    const handleSend = async () => {
        setSubmitting(true)
        setShowError(false);
        var data = JSON.stringify({
            "email": email, 
            "clientUrl": `${window.location.origin}/password-reset/${email}/`
        });
        var config = {
            method: 'post',
            url: `${window.__env.API_URL}/api/oauth/forgot-password`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //@ts-ignore
        await axios(config).then(() => {
            setSuccess(true);
        })
            .catch(function (error) {
                if (error.response) {
                    setError("Invalid login");
                } else if (error.request) {
                    // The request was made but no response was received
                    setError("Connection refused. Please try again later");
                } else {
                    // Something happened in setting up the request that triggered an Error
                    setError("Connection refused. Please try again later");
                }
                // TODO: @Van technically, both these states can be combined into one. Submitting and error
                // are not parallel states -- meaning an error cannot occur until the request is received (i.e., submit stopped)
                setSubmitting(false);
                setShowError(true)
            });
    };


    const renderContent = () => {
        if (!success) {
            return <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: 400 }}>
                    Can't remember your password?
                </Typography>
                <Typography variant="h6" sx={{ paddingTop: "8px", fontWeight: 400 }}>
                    No problem. Enter your email address below and we'll send you instructions to reset it.
                </Typography>
                <div style={{ marginTop: "16px" }}>
                    <TextField
                        type="email"
                        label="Email Address"
                        value={email}
                        required
                        onChange={(e) => {
                            if (showError) {
                                setShowError(false);
                                setError('')
                            }
                            setEmail(e.target.value)
                        }}
                        fullWidth
                    />
                </div>
            </Grid>
        } else {
            return <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: 400 }}>
                    Success!
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 400 }}>
                    Instructions for resetting your password have been emailed to you.
                </Typography>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "24px" }}>
                    <Button variant="contained" sx={{ backgroundColor: "#C4C4C4"}} onClick={() => navigate('/login')}>
                        Close
                    </Button>
                </div>
            </Grid>
        }
    }
    return <div style={{ height: "100vh", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Fade in={true} timeout={400}>
            <Box component={Paper} sx={{ width: "550px", height: "auto", padding: "24px" }}>
                <Grid container spacing={2} sx={{ display: "flex", alignContent: "space-between", height: "100%" }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" color="secondary" sx={{ fontWeight: "bold" }}>
                            Password Reset
                        </Typography>
                    </Grid>
                    {renderContent()}
                    <Grid item xs={12}>
                        <Collapse in={showError}>
                            <Typography color="error" sx={{ marginTop: "8px" }}>{error}</Typography>
                        </Collapse>
                    </Grid>
                </Grid>
                <Collapse in={!success}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                            <Button variant="contained" onClick={() => navigate('/login')}  sx={{ backgroundColor: "#C4C4C4"}}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                            {submitting ? (
                                <LoadingButton
                                    loading={submitting}
                                    variant="contained"
                                >
                                    Submit
                                </LoadingButton>
                            ) : (
                                <Button variant="contained" disabled={email.length === 0} onClick={() => handleSend()}>
                                    Send
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Collapse>
            </Box>
        </Fade>
    </div>
}

export default ForgotPassword;