import { Field, FieldProps, withTypes } from "react-final-form";
import { Button, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import formSchemaValidator from "../formSchemaValidator";
import { FormSubmitCallback } from "../types";

export type FormBaseProps<T> = {
    fields: FieldProps<string, any>[]
    initialValues?: T | {} | null,
    validationSchema: any,
    buttonSubmitLabel?: string,
    buttonCancelLabel?: string,
    readonly?: boolean,
    onSubmit: FormSubmitCallback<T>
    onCancel: (() => void) | null
}

export default function FormBase<T>({ 
    fields, 
    initialValues, 
    validationSchema, 
    buttonSubmitLabel = "Save",
    buttonCancelLabel = "Cancel",
    readonly = false,
    onSubmit, 
    onCancel 
}: FormBaseProps<T>) {
    const { Form } = withTypes<T>()
    return (
        <Form 
            initialValues={initialValues ?? {}} 
            validate={formSchemaValidator(validationSchema)} 
            onSubmit={onSubmit} render={({ form, submitting, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={4} rowSpacing={4} style={{ marginTop: 0 }}>
                        {fields.map((field, i) => (
                            field?.type === 'hidden'
                                ? (
                                    <Field 
                                        key={`baseform-field-hidden-${i}`} 
                                        name={field.name} 
                                        type="hidden" 
                                        defaultValue={field.value}
                                        component="input" />
                                )
                                : (
                                    <Grid key={`baseform-field-${i}`} item container xs={field.grid.xs}>
                                        <Field {...field} disabled={submitting || readonly} />
                                    </Grid>
                                )
                        ))}
                        <Grid
                            item
                            xs={12}
                            sx={{ display: "flex", justifyContent: "flex-start" }}
                        >
                            { onCancel !== null ? (
                                <Button
                                    disableElevation
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        marginRight: "16px",
                                        backgroundColor: "#C4C4C4",
                                        "&:hover": {
                                            color: "#FFF",
                                            backgroundColor: "red.main",
                                        },
                                    }}
                                    onClick={onCancel}
                                >
                                    { buttonCancelLabel }
                                </Button>
                            ) : null }
                            <LoadingButton
                                disabled={readonly}
                                loading={submitting}
                                disableElevation
                                variant="contained"
                                size="large"
                                type="submit"
                                sx={{
                                backgroundColor: "primary.main",
                                "&:hover": {
                                    backgroundColor: "primary.light",
                                },
                                }}
                            >
                                { buttonSubmitLabel }
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            )}>

        </Form>
    )
}