import { GridColDef } from "@mui/x-data-grid";
import InputField from "../../../components/Form/InputField";
import SelectField from "../../../components/Form/SelectField";
import { format } from "date-fns";
import { FieldProps } from "react-final-form";

const MANAGE_USER_FIELDS: FieldProps<string, any>[] = [
  {
    name: "firstName",
    label: "First Name",
    render: InputField,
    required: true,
    fullWidth: true,
    grid: {
      xs: 6,
    },
  },
  {
    name: "middleName",
    label: "Middle Name",
    render: InputField,
    required: false,
    fullWidth: true,
    grid: {
      xs: 6,
    },
  },
  {
    name: "lastName",
    label: "Last Name",
    render: InputField,
    required: true,
    fullWidth: true,
    grid: {
      xs: 6,
    },
  },
  {
    name: "roleType",
    label: "User Role / Type",
    render: SelectField,
    required: true,
    fullWidth: true,
    grid: {
      xs: 6,
    }
  },
  {
    name: "jobTitle",
    label: "Job Title",
    render: InputField,
    required: false,
    fullWidth: true,
    grid: {
      xs: 12,
    },
  },
  {
    name: "email",
    label: "Email",
    render: InputField,
    required: true,
    fullWidth: true,
    grid: {
      xs: 12,
    },
  }
];

const DEFAULT_USER_TABLE_GRID_DEF: GridColDef[] = [
  { 
    field: "name", 
    headerName: "Name", 
    flex: 1 
  },
  {
    field: "startDate",
    headerName: "Start Date",
    flex: 1,
    resizable: false,
    editable: false,
    width: 80,
    renderCell: (params) =>
      params?.value
        ? format(new Date(params.value.toString()), "MM/dd/yyyy")
        : "",
  },
  {
    field: "endDate",
    headerName: "End Date",
    flex: 1,
    resizable: false,
    editable: false,
    width: 80,
    renderCell: (params) =>
      params?.value
        ? format(new Date(params.value.toString()), "MM/dd/yyyy")
        : "",
  },
  { 
    field: "roleType", 
    headerName: "Role / Type", 
    flex: 1 
  },
];

const LINKED_CHILDREN_GRID_DEF = [
  { field: "name", headerName: "Child Last Name, First Name", flex: 1 },
  { field: "dob", headerName: "Date of Birth", flex: 1 },
];

const mockUsers = [
  {
    id: "AC4E37CA-1690-1189-8637-688D3A5A671E",
    name: "Clarke Marsh",
    startDate: "Oct 24, 2022",
    endDate: "Nov 2, 2023",
    role: "ADMIN",
    linkedData: [
      {
        id: "AC4E37CA-1690-1189-8637-688D3A5A671E",
        dob: "Nov 8, 2023",
        firstName: "Kimberly",
        lastName: "Bolton",
      },
      {
        id: "5DAB197B-13AE-569D-2979-64D9D2C3D5D0",
        dob: "Sep 5, 2023",
        firstName: "Amanda",
        lastName: "Mullins",
      },
      {
        id: "AD8B62AE-2B35-CFB8-751E-C6678A796EDC",
        dob: "Sep 19, 2022",
        firstName: "Beck",
        lastName: "Ramirez",
      },
      {
        id: "16DD4E4E-6A9E-3D89-E766-4C6502197320",
        dob: "Sep 18, 2022",
        firstName: "Yoshio",
        lastName: "Payne",
      },
      {
        id: "93F1D67A-A811-EE7C-9231-72A54C9A6A94",
        dob: "Jul 22, 2023",
        firstName: "Aurelia",
        lastName: "Blackwell",
      },
    ],
  },
  {
    id: "5DAB197B-13AE-569D-2979-64D9D2C3D5D0",
    name: "Linda Lindsey",
    startDate: "May 3, 2022",
    endDate: "Sep 29, 2023",
    role: "ADMIN",
    linkedData: [
      {
        id: "AC4E37CA-1690-1189-8637-688D3A5A671E",
        dob: "Nov 8, 2023",
        firstName: "Kimberly",
        lastName: "Bolton",
      },
      {
        id: "5DAB197B-13AE-569D-2979-64D9D2C3D5D0",
        dob: "Sep 5, 2023",
        firstName: "Amanda",
        lastName: "Mullins",
      },
      {
        id: "AD8B62AE-2B35-CFB8-751E-C6678A796EDC",
        dob: "Sep 19, 2022",
        firstName: "Beck",
        lastName: "Ramirez",
      },
      {
        id: "16DD4E4E-6A9E-3D89-E766-4C6502197320",
        dob: "Sep 18, 2022",
        firstName: "Yoshio",
        lastName: "Payne",
      },
      {
        id: "93F1D67A-A811-EE7C-9231-72A54C9A6A94",
        dob: "Jul 22, 2023",
        firstName: "Aurelia",
        lastName: "Blackwell",
      },
    ],
  },
  {
    id: "AD8B62AE-2B35-CFB8-751E-C6678A796EDC",
    name: "Halla Matthews",
    startDate: "Mar 11, 2023",
    endDate: "Oct 20, 2023",
    role: "STANDARD",
    expired: true,
    linkedData: [
      {
        id: "AC4E37CA-1690-1189-8637-688D3A5A671E",
        dob: "Nov 8, 2023",
        firstName: "Kimberly",
        lastName: "Bolton",
      },
      {
        id: "5DAB197B-13AE-569D-2979-64D9D2C3D5D0",
        dob: "Sep 5, 2023",
        firstName: "Amanda",
        lastName: "Mullins",
      },
      {
        id: "AD8B62AE-2B35-CFB8-751E-C6678A796EDC",
        dob: "Sep 19, 2022",
        firstName: "Beck",
        lastName: "Ramirez",
      },
      {
        id: "16DD4E4E-6A9E-3D89-E766-4C6502197320",
        dob: "Sep 18, 2022",
        firstName: "Yoshio",
        lastName: "Payne",
      },
      {
        id: "93F1D67A-A811-EE7C-9231-72A54C9A6A94",
        dob: "Jul 22, 2023",
        firstName: "Aurelia",
        lastName: "Blackwell",
      },
    ],
  },
  {
    id: "16DD4E4E-6A9E-3D89-E766-4C6502197320",
    name: "Mara Burks",
    startDate: "Aug 11, 2022",
    endDate: "Oct 9, 2022",
    role: "STANDARD",
    linkedData: [
      {
        id: "AC4E37CA-1690-1189-8637-688D3A5A671E",
        dob: "Nov 8, 2023",
        firstName: "Kimberly",
        lastName: "Bolton",
      },
      {
        id: "5DAB197B-13AE-569D-2979-64D9D2C3D5D0",
        dob: "Sep 5, 2023",
        firstName: "Amanda",
        lastName: "Mullins",
      },
      {
        id: "AD8B62AE-2B35-CFB8-751E-C6678A796EDC",
        dob: "Sep 19, 2022",
        firstName: "Beck",
        lastName: "Ramirez",
      },
      {
        id: "16DD4E4E-6A9E-3D89-E766-4C6502197320",
        dob: "Sep 18, 2022",
        firstName: "Yoshio",
        lastName: "Payne",
      },
      {
        id: "93F1D67A-A811-EE7C-9231-72A54C9A6A94",
        dob: "Jul 22, 2023",
        firstName: "Aurelia",
        lastName: "Blackwell",
      },
    ],
  },
  {
    id: "93F1D67A-A811-EE7C-9231-72A54C9A6A94",
    name: "Shad Sullivan",
    startDate: "Sep 20, 2023",
    endDate: "Jun 14, 2023",
    role: "ADMIN",
    linkedData: [
      {
        id: "AC4E37CA-1690-1189-8637-688D3A5A671E",
        dob: "Nov 8, 2023",
        firstName: "Kimberly",
        lastName: "Bolton",
      },
      {
        id: "5DAB197B-13AE-569D-2979-64D9D2C3D5D0",
        dob: "Sep 5, 2023",
        firstName: "Amanda",
        lastName: "Mullins",
      },
      {
        id: "AD8B62AE-2B35-CFB8-751E-C6678A796EDC",
        dob: "Sep 19, 2022",
        firstName: "Beck",
        lastName: "Ramirez",
      },
      {
        id: "16DD4E4E-6A9E-3D89-E766-4C6502197320",
        dob: "Sep 18, 2022",
        firstName: "Yoshio",
        lastName: "Payne",
      },
      {
        id: "93F1D67A-A811-EE7C-9231-72A54C9A6A94",
        dob: "Jul 22, 2023",
        firstName: "Aurelia",
        lastName: "Blackwell",
      },
    ],
  },
];

export {
  MANAGE_USER_FIELDS,
  DEFAULT_USER_TABLE_GRID_DEF,
  LINKED_CHILDREN_GRID_DEF,
  mockUsers,
};
