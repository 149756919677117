import { useMutation } from "@apollo/client";
import { EXPIRE_PERSON_MUTATION } from "../../../features/graphql/mutation/person/expirePersonMutation";
import { useState } from "react";
import { ExpirePersonMutation, ExpirePersonMutationVariables, PeopleForLinkQuery } from "../../../__generated__/graphql";
import { PEOPLE_FOR_LINK_QUERY } from "../../../features/graphql/query/person/getPeopleForLink";
import { getFragmentData } from "../../../__generated__";
import { PEOPLE_FOR_LINK__PERSON_FRAGMENT } from "../../../features/graphql/query/person/fragments/peopleForLink__person";
import { PERSON_FOR_LINK_QUERY } from "../../../features/graphql/query/person/getPersonForLink";
import { useSelectedPerson } from "../Person/SelectedPersonProvider";

const useExpirePerson = () => {
  const { setSelectedPerson } = useSelectedPerson();
  const [expirePersonMutation, { loading }] = 
    useMutation<ExpirePersonMutation, ExpirePersonMutationVariables>(EXPIRE_PERSON_MUTATION);

  const [personToDelete, setPersonToDelete] = useState<string>();

  const expirePerson = async (personId: string) => {
    await expirePersonMutation({
      variables: {
        personId,
      },
      refetchQueries: [{ 
        query: PERSON_FOR_LINK_QUERY,
        variables: {
          personId
        }
      }],
      update(cache) {
        const people = cache.readQuery<PeopleForLinkQuery>({ query: PEOPLE_FOR_LINK_QUERY });

        const newPeople = people?.persons.map(person => {
          const fragment = getFragmentData(PEOPLE_FOR_LINK__PERSON_FRAGMENT, person);
          if (fragment.id !== personId) return fragment;

          const updateFragment = {
            ...fragment,
            expired: true
          }

          setSelectedPerson(updateFragment);

          return updateFragment
        });

        cache.writeQuery<PeopleForLinkQuery>({
          query: PEOPLE_FOR_LINK_QUERY,
          data: { persons: newPeople ?? [] }
        });
      }
    })
  }


  const onExpirePerson = async (id: string) => {
    await expirePerson(id);
    setPersonToDelete(undefined);
  };

  return { expirePerson, onExpirePerson, savingExpirePerson: loading, setPersonToDelete, personToDelete };
};

export { useExpirePerson };
