import {useEffect, useState} from "react";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {UnreadWorkListQuery} from "../../../__generated__/graphql";

export const UNREAD_WORKLIST_QUERY = gql`
    query UnreadWorkList {
        me {
            workLists(status: UNREAD) {
               id
                session {
                    id
                    data
                    student {
                        id
                        firstName
                        lastName
                    }
                }
                createdAt
            }
        }
    }
`

function mapWorkListToTableDefinition(data: UnreadWorkListQuery | undefined): WorkListTableData[] {
    const workLists = data?.me?.workLists;
    const mappedWorkLists = workLists?.map(workList => {
        /**
         * Say we have the given table:
         *
         * |     Risk     |    Protective    |
         * |--------------|------------------|
         * | Anxiety      | Family           |
         * | Depression   | Protective       |
         * | Family Risk  | Self Protective  |
         * | Suicidality  |                  |
         *
         * The code below avoid possible exceptions and turns the table into a flat list e.g.,
         * Anxiety, Depression, Family Risk, Family, Protective, and Self Protective which we
         * can use for simple in array checks
         */
        const sessionData = workList.session?.data || '{}'; // default blank object to avoid possible exception
        const sessionIndicators = Object.values(JSON.parse(sessionData)?.tags || [])?.flat() || [];

        return {
            id: workList.session?.id,
            studentId: workList.session?.student.id,
            name: `${workList.session?.student?.firstName} ${workList.session?.student?.lastName}`,
            createdAt: workList.createdAt,
            urgency: sessionIndicators.includes('Suicidality') ? 'Urgent' : ''
        }
    }) as WorkListTableData[] || [];

    if (mappedWorkLists.length === 0)
        return [];

    return removePotentialDuplicates(mappedWorkLists);
}

function sortUrgentFirst(workLists: WorkListTableData[]): WorkListTableData[] {
    return [...workLists].sort((a: any, b: any) => {
        if (a.urgency) {
            if (b.urgency) {
                return a.date < b.date ? -1 : 1
            }
            return -1
        } else if (b.urgency) {
            return 1
        } else {
            return a.date < b.date ? -1 : 1
        }
    })
}

/**
 * On the off chance a student took multiple sessions in a short time period we don't want both
 * sessions to appear in action items list. First we sort the sessions by "urgency" and then
 * we remove N of the duplicates ensuring only record for each student remains.
 *
 * Note: when the user of the application observes the student session, all other sessions
 * are available in view (at the time of writing this)
 * @param workLists
 */
function removePotentialDuplicates(workLists: WorkListTableData[]): WorkListTableData[] {
    const sorted = sortUrgentFirst(workLists);
    return sorted?.length > 0
      //@ts-ignore
      ? [...new Map(sorted.map(x => [x.studentId, x])).values()]
      : [];
}

export interface WorkListTableData {
    id: string | null,
    studentId: string | null,
    name: string | null,
    createdAt: string,
    urgency: string,
}

const TEN_SECONDS = 1000 * 10;

const useActionItems = () => {
    const {data, refetch} = useQuery<UnreadWorkListQuery>(UNREAD_WORKLIST_QUERY, {
        fetchPolicy: 'cache-and-network',
        pollInterval: TEN_SECONDS
    });
    const [actionItems, setActionItems] = useState<any>([]);

    useEffect(() => {
        void refetch()
    }, [])

    useEffect(() => {
        const workList = mapWorkListToTableDefinition(data) || [];
        setActionItems(workList);
    }, [data])

    return { actionItems };
};

export default useActionItems;