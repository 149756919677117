import { useEffect, useState } from "react";

import { Box, Button } from "@mui/material";
import { GridColDef, GridRowModel } from "@mui/x-data-grid";
import AddIcon from '@mui/icons-material/Add';
import { useLazyQuery } from "@apollo/client";

import { FragmentType, getFragmentData, gql } from "../../../__generated__";
import QuickSearchToolbar from "../../Admin/components/StudentsGrid/SearchBar";
import Table from "../../../components/Table/Table";
import TableRemoveButton from "../components/TableRemoveButton";

const DEFAULT_PAGE_SIZE = 100;

const TABLE_COLUMNS: GridColDef[] = [
    { field: "firstName", headerName: "User First Name", flex: 1, sortable: true },
    { field: "lastName", headerName: "Last Name", flex: 1, sortable: true },
    // { field: "name", headerName: "User Name", flex: 1, sortable: false },
    { field: "role", headerName: "Role", flex: 1, sortable: false },
];

export const STUDENT_PERSONS_LINK_PREVIEW_QUERY = gql(`
    query StudentPersonsLinkPreview($studentId: UUID!) {
        student(id: $studentId) {
            __typename
            id
            references
            persons(status: ACTIVE) {
                ... _studentPerson
            }
        }
    }
`);

const STUDENT_PERSONS_PERSON_FRAGMENT = gql(`
    fragment _studentPerson on Person {
        __typename
        id
        firstName
        lastName
        role {
            name
            normalizedName
        }
}
`);

const mapQueryToTable = (edge: FragmentType<typeof STUDENT_PERSONS_PERSON_FRAGMENT>): GridRowModel => {
    const fragment = getFragmentData(STUDENT_PERSONS_PERSON_FRAGMENT, edge);
    return {
        ...fragment,
        name: `${fragment.lastName} ${fragment.firstName}`,
        role: fragment.role?.name,
        normalizedRole: fragment.role?.normalizedName
    } as const
}

export interface StudentTableLinkPreviewProps {
    studentId: string,
    removeRowId: string | null,
    onLink: (id: string) => void
    onRemove: (id: string) => void
    onDisabled: (reason: 'NOT_ENOUGH_REFERENCES' | 'UNKNOWN') => void
}

const StudentTableLinkPreview = ({
    studentId,
    removeRowId,
    onLink,
    onRemove,
    onDisabled
}: StudentTableLinkPreviewProps) => {

    const [pageTableState, setPageTableState] = useState<{ columns: GridColDef[], rows: GridRowModel[] }>({
        columns: [],
        rows: []
    });

    const [fetchRecords, { data, loading, error, fetchMore }] = useLazyQuery(STUDENT_PERSONS_LINK_PREVIEW_QUERY, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
            studentId,
            // first: DEFAULT_PAGE_SIZE,
        }
    });

    useEffect(() => {
        // if (selectedPerson) return;

        async function fetchResults() {
            await fetchRecords();
        }

        fetchResults();
    }, [])

    useEffect(() => {
        if (!studentId) return;
        // TODO -- Make student edge fragment generic. For now ignore TypeScript error
        setPageTableState(prevState => {
            const rows = data?.student.persons?.map(mapQueryToTable) ?? []
            return {
                ...prevState,
                rows
            }
        });
    }, [studentId, loading, data])

    useEffect(() => {
        if (!onRemove) return;

        setPageTableState(prevState => {
            const columns = [...TABLE_COLUMNS, {
                field: 'remove',
                headerName: 'Remove',
                align: 'center',
                sortable: false,
                width: 100,
                renderCell: (params) => {
                    return (
                        <TableRemoveButton
                            id={params.row.id}
                            removing={params.row.id === removeRowId}
                            onRemove={onRemove}
                            onDisabled={() => {
                                if ((data?.student.references ?? 0) <= 1) {
                                    return onDisabled("NOT_ENOUGH_REFERENCES");
                                }

                                onDisabled("UNKNOWN")
                            }}
                            disabled={(data?.student.references ?? 0) <= 1} />
                    )
                }
            } as GridColDef];

            return {
                ...prevState,
                columns
            }
        })
    }, [onRemove, data]);

    const handleLink = (id: string) => {
        if (!onLink) return;
        onLink(id)
    }

    return (
        <Table
            loading={loading}
            renderToolbar={({ searchQuery, onSearch, onSearchClear }) => {
                return (
                    <Box component="div">
                        <QuickSearchToolbar
                            value={searchQuery}
                            clearSearch={onSearchClear}
                            onChange={onSearch}>
                            <Button
                                color="secondary"
                                onClick={x => handleLink(studentId ?? '')}
                                startIcon={
                                    <AddIcon
                                        style={{
                                            backgroundColor: "#253D93",
                                            borderRadius: "100%",
                                            color: "white",
                                        }}
                                    />
                                }
                            >
                                Link Users
                            </Button>
                        </QuickSearchToolbar>
                    </Box>
                )
            }}
            columns={pageTableState.columns}
            rows={pageTableState.rows}
            pageSize={5}
            disableBoxShadow={true}
        />
    )
}

export default StudentTableLinkPreview;