import { useMutation } from "@apollo/client";
import { gql } from "../../../../__generated__";
import useSelectStudent, { SELECTED_STUDENT_QUERY } from "./useSelectedStudent";

export const EXPIRE_STUDENT_MUTATION = gql(/* GraphQL */`
    mutation ExpireStudent($id: UUID!) {
        expireStudent(input: { id: $id }) {
            __typename
            student {
                id
                expired
            }
            errors {
                __typename
                ... on ValidationError {
                    errors {
                    fieldName
                    messages
                    }
                }
                ... on EntityLockedError {
                    id
                    entity
                    message
                    updatedAt
                }
                ... on EntityNotFoundError {
                    id
                    message
                }
                ... on UnknownError {
                    message
                }
            }
        }
    }
`);

export default function useExpireStudent() {
    const [expireStudentMutation, { loading }] = useMutation(EXPIRE_STUDENT_MUTATION);
    const { student, setSelectedStudent } = useSelectStudent();

    const expireStudent = async (id: string) => {
        const response = await expireStudentMutation({
            variables: {
                id
            },
            update(cache, { data }) {
                const record = {
                    __typename: 'Student',
                    id
                }
            }
        })

        if (!response.errors) {
            // The UI must update when a user is expired. We trigger this update with the dirty code below.
            // This process should be refactored to lean more on the Apollo client, so that cache updates
            // are handeled more intuitively. For now though, this works.
            setSelectedStudent({
                ...student,
                expired: true,
                endDate: new Date()
            })
        }
    }

    return { expireStudent, loading } as const;
}