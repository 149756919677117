import React, { useEffect, useState } from "react";
import { Grid, Button, TextField, Typography, Collapse } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";

import { validatePassword } from "./validation";
import { useSlideInContext } from "../context/SlideInProvider";
import { useWorkListContext } from "../../../../../context/workLists";
import usePersonAuthStore from "../../../personAuthStore";
import usePersonStore from "../../../personStore";
const LOADING = "loading";
const IDLE = "idle";
const SUCCESS = 'success';
const ERROR = 'error';

const ChangePassword = () => {
    const [state, setUIState] = useState(IDLE);
    const [responseError, setResponseError] = useState('')
    const { toggleDrawer } = useSlideInContext();
    // const { personId } = useWorkListContext();
    const accessToken = usePersonAuthStore(state => state.access_token);
    const personId = usePersonStore(state => state.id)
    // console.log('me: ', me)
    const [fieldValues, setFieldValues] = useState({
        newPassword: "",
        oldPassword: "",
        confirmNewPassword: ""
    });
    const { oldPassword, newPassword, confirmNewPassword } = fieldValues;
    const [errors, setErrors] = useState({
        newPassword: "",
        oldPassword: "",
        confirmNewPassword: ""
    });

    const handleChange = (
        name: string,
        value: string
    ) => {
        if (state === ERROR) {
            setUIState(IDLE);
            setResponseError("")
        }
        if (["newPassword", "confirmNewPassword"].includes(name)) {
            const error = validatePassword(value);
            if (error) {
                setErrors({
                    ...errors,
                    [name]: error
                })
            } else {
                setErrors({
                    ...errors,
                    [name]: ""
                })
            }
        }
        setFieldValues({
            ...fieldValues,
            [name]: value
        })
    };

    const handleSubmit = async () => {

        setUIState(LOADING);

        var data: any = JSON.stringify({
            "id": personId,
            "newPassword": newPassword,
            "oldPassword": oldPassword,
            "confirmPassword": confirmNewPassword,
        });

        var config = {
            method: 'post',
            url: `${window.__env.API_URL}/api/oauth/change-password`,
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${accessToken}`
            },
            data: data
        };

        //@ts-ignore
        await axios(config).then(() => {
            setUIState(SUCCESS)
        }).catch(function (error: any) {
            console.log("error", error)
            setResponseError("Connection refused. Please try again later.");
            // if (error.response) {
            //     setResponseError(error.response);
            // } else if (error.request) {
            //     // The request was made but no response was received
            //     setResponseError("Connection refused. Please try again later.");
            // } else {
            //     // Something happened in setting up the request that triggered an Error
            //     setResponseError("Connection refused. Please try again later.");
            // }
            setUIState(ERROR)
        });
    }
    const submitDisabled = !Boolean(
        newPassword && oldPassword && confirmNewPassword
        && (confirmNewPassword === newPassword)
        && !Boolean(errors.confirmNewPassword || errors.newPassword || errors.oldPassword)
    )
    return <div style={{ color: "#fff", marginTop: "24px" }}>
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <TextField label="Confirm Password" type="password"
                    value={oldPassword}
                    onChange={(e) => handleChange("oldPassword", e.target.value)}
                    fullWidth
                    error={Boolean(errors.oldPassword)}
                    helperText={errors.oldPassword || ""}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <TextField label="New Password" type="password"
                    value={newPassword}
                    onChange={(e) => handleChange("newPassword", e.target.value)}
                    error={Boolean(errors.newPassword)}
                    helperText={errors.newPassword || ""}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <TextField label="Confirm Password" type="password"
                    value={confirmNewPassword}
                    onChange={(e) => handleChange("confirmNewPassword", e.target.value)}
                    error={Boolean(errors.confirmNewPassword)}
                    helperText={errors.confirmNewPassword || ""}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <Collapse in={[SUCCESS, ERROR].includes(state)}>
                    {state === SUCCESS ? <Typography color="primary" sx={{ textAlign: "center" }}>
                        Your password was successfully changed!
                    </Typography> :
                        <Typography sx={{ textAlign: "center", color: "#ffc107" }}>
                            {responseError}
                        </Typography>
                    }
                </Collapse>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                <Button variant="contained" onClick={() => toggleDrawer()}>
                    Cancel
                </Button>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                <LoadingButton loading={Boolean(state === LOADING)} variant="contained" onClick={() => handleSubmit()} disabled={submitDisabled}>
                    Submit
                </LoadingButton>
            </Grid>
        </Grid>
    </div>
}

export default ChangePassword;