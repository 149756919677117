import React from "react";
import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { FieldRenderProps } from "react-final-form";
import { Meta, InputProps } from "../types";

type Props = {
  label: string;
  options: SelectOption[];
  required?: boolean;
};

export type SelectOption = {
  label: string;
  value: string;
  isDefault: boolean;
};

export type SelectProps = FieldRenderProps<Props>;

export const getDefaultValue = (options: SelectOption[]): string => 
    options?.find(x => x.isDefault)?.value ?? '';

const SelectField: React.FC<SelectProps> = ({input, meta, loading, disabled, options, helperText, ...rest}: SelectProps) => {

  const { invalid, touched, error, submitError } = meta;

  const hasError = invalid && touched;

  return (
    <TextField {...rest} {...input}  
      helperText={hasError ? submitError || error : helperText} 
      value={loading ? "" : input.value ? input.value : meta.initial}
      error={hasError} 
      InputProps={
        loading
        ? {
          endAdornment: (
            <InputAdornment position="start" style={{ paddingRight: 16 }}>
                  <CircularProgress size={24} />
                </InputAdornment>
              ),
            }
            : {}
          }
      children={
        options ? (
          options.map((option: SelectOption) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))
        ) : loading ? (
          <MenuItem value="">Loading...</MenuItem>
        ) : (
          <div></div>
        )
      }
      disabled={disabled || loading}
      fullWidth
      select
    />
  );
};

SelectField.displayName = "SelectField";

export default SelectField;
