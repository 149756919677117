import { gql } from '@apollo/client';

export const ADD_PERSON_MUTATION = gql(/* GraphQL */ `
    mutation AddPerson(
        $firstName: String!
        $middleName: String
        $lastName: String!
        $jobTitle: String
        $email: String!
        $role: PersonRole!
        $startDate: DateTime!
    ) {
        addPerson(input: {
            firstName: $firstName,
            middleName: $middleName
            lastName: $lastName,
            jobTitle: $jobTitle,
            email: $email,
            userRole: $role,
            startDate: $startDate
        }) {
            __typename
            person {
                id
                companyId
                firstName
                lastName
                createdAt
                company {
                id
                createdAt
                }
            }
            errors {
                __typename
                ... on ValidationError {
                    errors {
                        fieldName
                        messages
                    }
                }
                ... on EntityNotFoundError {
                    id
                    message
                }
                ... on UnknownError {
                    message
                }
            }
        }
    }
`);