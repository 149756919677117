export const defaultAssetsToLoad = [
    `/assets/assessment/select_adventure_bg.png`,
    `/assets/assessment/doors/beach.jpg`,
    `/assets/assessment/doors/apocalypse.jpg`,
    `/assets/assessment/doors/playground.jpg`,
    `/assets/assessment/doors/science.jpg`,
    `/assets/assessment/overview/classroom.png`,
    `/assets/assessment/overview/apocalypse.png`,
    `/assets/assessment/overview/playground.png`,
    `/assets/assessment/overview/urbangarden.png`,
    "/assets/background/game_room_empty.png",
    "/assets/background/lobby_empty.jpg",
    "/assets/background/empty_room.png",
    `/assets/avatars/male.png`,
    `/assets/avatars/female.png`,
    "/assets/background/survey_bg.png",
    "/assets/background/welcome-bg.png",
    "/assets/background/game_room_bg.png",
    "/assets/background/player_confirm_bg.png"
]