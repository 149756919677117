import { z } from "zod";

/**
 * Validate form schema using Zod type
 * 
 * @param schema Zod schema type
 * @returns error dictionary representing invalid fields
 */
export default function formValidator<T extends z.ZodType<any, any>>(schema: T) { 
    return (values: any) => {
        try {
            schema.parse(values);
        } catch (err) {
            return (err as z.ZodError).formErrors.fieldErrors;
        }
    };
}