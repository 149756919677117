import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface LocationModel {
  id: string | null,
  name: string,
  // streetAddressOne: string | null | undefined,
  // streetAddressTwo: string | null | undefined,
  streetLineOne: string | null | undefined,
  streetLineTwo: string | null | undefined,
  city: string | null | undefined,
  state: string | null | undefined,
  postalCode: string | null | undefined,
  country: string | null | undefined
}
export interface LocationsConnection {
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string | null;
    endCursor: string | null;
  };
  edges: Array<{
    node: LocationModel;
  }>;
}
export interface PersonModel {
  id: null | string,
  company: {
    id: null | string,
    defaultLocation: LocationModel | null
  },
  locations: Array<LocationModel>
  // locations: LocationsConnection | null
}

export interface PersonStoreState extends PersonModel {
  setState: (data: PersonModel) => void,
  setDefaultLocation: (location: LocationModel) => void,
  destroy: () => void
}

const initialState = {
  id: null,
  company: {
    id: null,
    defaultLocation: null
  },
  locations: []
}

const usePersonStore = create<PersonStoreState>()(
  /* @ts-ignore */
  persist(
    (set) => ({
      id: null,
      company: {
        id: null,
        defaultLocation: null,
      },
      locations: [],
      setState: (data) => {
        set(() => data)
      },
      setDefaultLocation: (location: LocationModel) => {
        set((state) => ({
          ...state,
          company: {
            ...state.company,
            defaultLocation: location
          },
          locations: [...state.locations]
        }))
      },
      destroy: () => set(() => initialState)
    }),
    {
      name: 'active-person',
      getStorage: () => sessionStorage
    }
  ),
);

export default usePersonStore;