import { Box, Chip, Grid } from "@mui/material";
import './TagCell.css';

export const TagsCell = ({ flags, status }: { flags?: { [key: string]: [string], risk: [string], protective: [string]}, status: string | null | undefined}) => {
    if (flags) {
      const tags = Object.keys(flags)
      .flatMap((type: string) => flags[type].map((name: string) => ({ type, name })))
      .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      .map((tuple) => 
            <Grid key={`tags-chip-${tuple.type}-${tuple.name}`} item style={{ marginTop: 1 }}>
              <Chip 
                className={`chip ${tuple.type}`}
                label={tuple.name} 
                data-state={ (!status || status === 'Unread') && tuple.name.toLocaleUpperCase() === 'SUICIDALITY' ? 'on' : 'off' } />
            </Grid>
        );
  
      return (
        <Grid container spacing={1} sx={{ padding: 2 }} >
          { tags }
        </Grid>
      );
    }  
    
    return (
      <Box component="div" sx={{ width: "100%", textAlign: "center", padding: 2, marginTop: 1 }}>
        --
      </Box>
    );
  }