import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, useLocation, Routes, Navigate, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import { PlayerDataProvider } from "../context/playerContext/PlayerDataProvider";
import { PreloadImagesProvider } from "../context/PreloadImages/PreloadImagesProvider";
import { RouteContextProvider } from "../context/routeContext";

import LoginPage from "./Login"
import ForgotPassword from "./ForgotPassword";
import PasswordReset from "./PasswordReset";

import usePersonAuthStore from './Admin/personAuthStore'
import { SelectedPersonProvider } from "./Manage/Person/SelectedPersonProvider";
import { PersonIndexPage } from "./Manage/Person/PersonIndexPage";
import { StudentIndexPage } from "./Manage/Students/StudentIndexPage";
import ManageModal, { ManageModalProvider } from "./Manage/components/ManageModal/ManageModal";
import { DashboardIndexPage } from "./Dashboard";
import { useInterval } from 'ft-common';

// components

const RenderRoutes = () => {
  const accessToken = usePersonAuthStore(state => state.access_token);
  const roles = usePersonAuthStore(state => state.roles);
  const setAuthValuesFromAccessToken = usePersonAuthStore(state => state.setAuthValuesFromAccessToken);

  useEffect(() => {
    if (!accessToken || roles.length > 0) {
      return
    }

    setAuthValuesFromAccessToken(accessToken)
  }, [accessToken]);

  return (
    <PreloadImagesProvider>
      <Box
        component="div"
        sx={{ backgroundColor: "#F3F6F9", minHeight: "100vh", }}
      >
        <Router>
          <PlayerDataProvider>
            <RouteContextProvider>
              <SelectedPersonProvider>
                <ManageModalProvider>
                  <ManageModal />
                  <Routes>
                    <Route path="login" element={<LoginPage />} />
                    <Route path="forgot-password" element={<ForgotPassword />} />
                    <Route path="password-reset/:email" element={<PasswordReset />} />
                    <Route path="manage/children/*" element={<StudentIndexPage />} />
                    <Route path="manage/users/*" element={<PersonIndexPage />} />
                    <Route path="dashboard/*" element={<DashboardIndexPage />} />
                    <Route path="*" element={<Navigate to="login" replace />} />
                  </Routes>
                </ManageModalProvider>
              </SelectedPersonProvider>
            </RouteContextProvider>
          </PlayerDataProvider>
          <AuthenticationMonitor />
        </Router>
      </Box>
    </PreloadImagesProvider>
  );
};

function AuthenticationMonitor() {
  var navigate = useNavigate();
  var location = useLocation();

  const accessToken = usePersonAuthStore(state => state.access_token);
  const destory = usePersonAuthStore(state => state.destroy);
  
  // useEffect(() => {
  //   console.log('History ', location.pathname);
  // }, [location]);

  useInterval(() => {
    if (!accessToken) return;

    const payload = JSON.parse(atob(accessToken.split('.')[1]));
    if (location.pathname !== '/' && Date.now() >= (payload.exp * 1000)) {
      console.log('Access token expired!')
      destory();
      navigate('/', { replace: true });
    }
  }, 1000);

  return null;
}

export default RenderRoutes;
