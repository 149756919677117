import AddBoxIcon from '@mui/icons-material/AddBox';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import { GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import { Note } from '../../../../__generated__/graphql';
import { SimpleToolbar, Table } from '../../../../components/Table';
import { useSlideInContext } from '../SlideIn/context/SlideInProvider';
import { ADD_NOTE } from '../SlideIn/context/contentTypes';
import { gql } from '../../../../__generated__';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import { LoadingWrapper } from '../../../../components/LoadingWrapper';
import { NetworkError } from '../../../../components/NetworkError';
import { useWorkListContext } from '../../../../context/workLists';

export const SESSION_NOTES_QUERY = gql(/* GraphQL */`
  query SessionNotes($sessionId: UUID!) {
    session(id: $sessionId) {
      __typename
      id
      student {
        __typename
        id
        firstName
        lastName
      }
      notes {
        id
        type
        body
        createdAt
        person {
          id
          firstName
          lastName
          role {
            normalizedName
          }
        }
      }
    }
  }
`);

function mapDataToTable(notes: Note) {
  return {
    id: notes.id,
    type: notes.type,
    body: notes.body,
    username: `${notes.person?.firstName} ${notes.person?.lastName}`,
    createdAt: format(new Date(notes.createdAt), "MM/dd/yyyy")
  }
}

type HistoryTableParams = {
  sessionId: string
}

export default function HistoryTable({
  onBack,
}: {
  onBack: () => void;
}) {
  const params = useParams<HistoryTableParams>();
  const { setSession } = useWorkListContext();

  const { data, loading, error } = useQuery(SESSION_NOTES_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      sessionId: params.sessionId
    }
  });

  const [rows, setRows] = useState<any[]>([]);
  const { handleContent } = useSlideInContext();

  const handleAddNote = () => {
    setSession(data?.session as any);
    handleContent(ADD_NOTE);
  }

  useEffect(() => {
    if (!data) return;

    const notes = [...data.session.notes]
      .sort(function (a, b) {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      })
      .map(x => mapDataToTable(x as any));

    setRows(notes);
  }, [data]);

  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'createdAt',
        headerName: 'Date',
        sortable: false,
        resizable: false,
        editable: false,
        disableColumnMenu: true,
        headerAlign: 'center',
        align: 'center',
        width: 120
      },
      {
        field: 'type',
        headerName: 'Active',
        sortable: false,
        resizable: false,
        editable: false,
        disableColumnMenu: true,
        headerAlign: 'left',
        align: 'left',
        flex: .5
      },
      {
        field: 'body',
        headerName: 'Note',
        sortable: false,
        resizable: false,
        editable: false,
        disableColumnMenu: true,
        headerAlign: 'left',
        align: 'left',
        flex: 2
      },
      {
        field: 'username',
        headerName: 'Username',
        sortable: false,
        resizable: false,
        editable: false,
        disableColumnMenu: true,
        headerAlign: 'left',
        align: 'left',
        flex: 1
      },
    ]
  }, []);

  if (error?.networkError)
    return <NetworkError error={true} loading={false} />

  return (
    <LoadingWrapper loading={loading && !data} sx={{ marginTop: 4 }}>
      <Box component="div" sx={{ background: '#fff', paddingTop: 1, marginTop: 4, marginBottom: 8 }}>
        <Table 
          loading={loading} 
          renderToolbar={() => {
            return (
              <Box component="div" sx={{ paddingBottom: 1 }}>
                <SimpleToolbar 
                  title={`Activity History for ${data?.session.student.firstName} ${data?.session.student.lastName}`}
                  onBack={onBack} 
                  rightSlot={() => ( 
                    <Box component="div" sx={{ display: "flex", justifyContent: "end" }}>
                      <Button 
                        color="secondary"
                        variant="text"
                        startIcon={<AddBoxIcon />} 
                        onClick={handleAddNote} 
                        sx={{ paddingTop: 1.5, paddingBottom: 1.5, paddingLeft: 3, paddingRight: 3 }}
                      >
                        Add Note
                      </Button>
                    </Box>
                  )} 
                />      
              </Box>
            )
          }}
          columns={columns} 
          rows={rows} 
          pageSize={10} 
          disableBoxShadow={false} />
      </Box>
    </LoadingWrapper>
  );
}
