import { gql } from "../../../../__generated__";

export const EDIT_STUDENT_PARENT_EMAIL = gql(`
   mutation EditStudentParentEmail($id: UUID!, $parentEmail: String!) {
    editStudentParentEmail(input: { id: $id, parentEmail: $parentEmail }) {
      __typename
      student {
        id
      }
      errors {
        ... on ValidationError {
          message
          errors {
            fieldName
            messages
          }
        }
      }
    }
   }
`);