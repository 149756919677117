import { gql } from "@apollo/client";

export const WORKLISTS_BY_PERSON_QUERY = gql`
  query WorkListsByPerson {
      me {
        id
        firstName
        lastName
        workLists {
          id
          type
          status
          createdAt
          notes {
              data
              id
              createdAt
              body
              type
          }
          person {
            id
            firstName
            lastName
          }
          session {
            id
            data
            createdAt
            student {
              id
              firstName
              lastName
              createdAt
            }
            report {
              id
            }
          }
          createdAt
        }
      }
    }
`;
