import { onError } from '@apollo/client/link/error';

const AUTH_NOT_AUTHENTICATED = "AUTH_NOT_AUTHENTICATED";
const AUTH_NOT_AUTHORIZED = "AUTH_NOT_AUTHORIZED";

const errorLink = onError(({ graphQLErrors, networkError }) => {
    const excludeRoutes = ['/login', '/password-reset', '/forgot-password', '/survey/lobby'];

    if (graphQLErrors) {
        graphQLErrors.map(({ message, extensions }) => {

            if (window.location.pathname === '/')
            {
                window.location.assign('/login');
                return;
            }

            if (!extensions || excludeRoutes.some(x => x.includes(window.location.pathname)))
                return;

            if ([AUTH_NOT_AUTHENTICATED, AUTH_NOT_AUTHORIZED].includes(extensions.code)) {
                sessionStorage.clear();
                window.location.assign('/login');
                return;
            }

            console.log(`GraphQL Error: ${message}`)
        });
    }
    
    if (networkError) {
        console.log(`Network Error: ${networkError.message}`);
    }
});

export default errorLink;
export { AUTH_NOT_AUTHENTICATED }