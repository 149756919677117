import React, { useContext, useEffect, useMemo, useState } from "react";
import { FragmentType, getFragmentData, gql } from "../../../../__generated__";
import { useLazyQuery } from "@apollo/client";
import { SelectedStudent_StudentFragment } from "../../../../__generated__/graphql";

export const SELECTED_STUDENT_QUERY = gql(/* GraphQL */` 
    query SelectedStudent($id: UUID!) {
        student(id: $id) {
            ... SelectedStudent_Student
        }
    }
`);

export const SELECTED_STUDENT_FRAGMENT = gql(/* GraphQL */`
  fragment SelectedStudent_Student on Student {
    __typename
    id 
    locationId
    firstName
    middleName
    lastName
    dob
    postalCode
    parentEmail
    parentPhone
    startDate
    endDate
    expired
    references
    avatars {
        __typename
        id
        bodyId
        bodyColor
        eyeColor
        hairColor
        helperId
        shirtColor
        pantsColor
        shoesColor
    }
    studentCustomFields {
        __typename
        value
        customField {
            __typename
            id
        }
    }
  } 
`);

export type SelectedStudentFragment = FragmentType<typeof SELECTED_STUDENT_FRAGMENT>;
export type StudentDataType = Partial<SelectedStudent_StudentFragment> | null;

export interface SelectedPersonContextType {
    student: StudentDataType,
    setSelectedStudent: React.Dispatch<React.SetStateAction<StudentDataType>>,
}

export const SelectedStudentContext = React.createContext<SelectedPersonContextType | null>(null);
SelectedStudentContext.displayName = "SelectedStudentContext";

export interface SelectedStudentProviderProps {
    children: React.ReactNode
}

export const SelectedStudentProvider = ({ children }: SelectedStudentProviderProps) => {
    const [student, setSelectedStudent] = useState<StudentDataType>(null);

    const memorizedValue = useMemo(() => ({
        student, setSelectedStudent
    }), [student, setSelectedStudent]);

    return (
        <SelectedStudentContext.Provider value={memorizedValue}>
            { children }
        </SelectedStudentContext.Provider>
    )
}

type SelectedStudentHook = {
    loading: boolean,
    student: StudentDataType,
    setSelectedStudent: React.Dispatch<React.SetStateAction<StudentDataType>>,
    fetchSelectedStudent: (id: string) => void
}

export const useSelectPersonContext = () => {
    const context = useContext(SelectedStudentContext);

    if (!context) 
    throw new Error('Call made outside provider. You must use useSelectPersonContext within the SelectedPersonProvider provider.');

    return context;
}

export default function useSelectStudent(id?: string | null): SelectedStudentHook {

    const { student, setSelectedStudent } = useSelectPersonContext();
    const [fetch, { data, loading }] = useLazyQuery(SELECTED_STUDENT_QUERY, {
        // Required for if the query is refetched.
        notifyOnNetworkStatusChange: true,
    });

    const fetchSelectedStudent = (id: string) => {
        fetch({
            variables: {
                id
            }
        })
    }

    useEffect(() => {
        if (!id || student) return;
        fetchSelectedStudent(id);
    }, [student]); 

    useEffect(() => {
        if (loading || !data) return;
        const fragment = getFragmentData(SELECTED_STUDENT_FRAGMENT, data.student);
        setSelectedStudent(fragment);
    }, [loading, data]);

    return { loading, student, setSelectedStudent, fetchSelectedStudent };
}