import { Box, CircularProgress, Fade, Paper, Typography } from "@mui/material";

export default function LoadingWrapper({ loading, children, ...rest }: { loading: boolean, children: React.ReactNode, [x:string]: any; }) {
    return (
        <Box component="div" {...rest}>
        { loading 
            ? (
                <Paper elevation={2} sx={{ marginTop: 1 }}>
                    <div style={{ height: "50vh", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <CircularProgress color="secondary" />
                        <Typography sx={{ marginTop: "16px" }} variant="h5">Loading...</Typography>
                    </div>
                </Paper>)
            : (
                <Fade appear in timeout={350}>
                    <div>
                        {children}
                    </div>
                </Fade>
            )
        }
        </Box>
    )
}