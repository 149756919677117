import React from "react";
import {differenceInYears, format, parseISO} from "date-fns";
import {dateOnlyISO8601} from "../../library/date";
import {Tooltip} from "@mui/material";

export interface DateCellProps {
  value: string,
}

function DateCell({ value }: DateCellProps): React.ReactElement {
  let dateISO = parseISO(dateOnlyISO8601(value))
  return (
    <Tooltip title={`Age ${differenceInYears(new Date(), dateISO)}`} placement="right">
      <span>{ format(dateISO, 'MM/dd/yyyy') }</span>
    </Tooltip>
  )
}

export default DateCell;