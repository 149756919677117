import React from "react";
import RenderRoutes from "./pages";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./features/mui/theme";
import Apollo from "./features/apollo";
import ErrorBoundary from "./features/errorHandling/ErrorBoundary";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        {/* @ts-ignore */}
        <ErrorBoundary>
        <Apollo>
          <div className="App">
            <RenderRoutes />
          </div>
        </Apollo>
        </ErrorBoundary>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
