import { Box } from "@mui/material";
import QuickSearchToolbar from "../../../Admin/components/StudentsGrid/SearchBar";
import { TableFilter, TableFilterOption } from "../TableFilter";
import Header from "../Header";
import { RenderToolbarProps } from "../TablePaginated/TablePaginated";

type Props = {
  title: string;
  subtitle?: string;
  newButtonLabel?: string;
  onNew?: () => void;
  onBack?: () => void;
  onFilter?: (option: TableFilterOption) => void;
};

export type TableToolbarStackedProps = Props & RenderToolbarProps;

export default function TableToolbarStacked({
  title,
  subtitle,
  newButtonLabel,
  searchQuery,
  onNew,
  onBack,
  onSearch,
  onSearchClear,
  onFilter,
}: TableToolbarStackedProps) {
  return (
    <Box component="div" sx={{ backgroundColor: "#fff" }}>
      <Header
        title={title}
        subtitle={subtitle}
        newLabelName={newButtonLabel}
        onNew={onNew}
        onBack={onBack}
      />
      <QuickSearchToolbar
        value={searchQuery}
        onChange={onSearch}
        clearSearch={onSearchClear}
      >
        { onFilter && <TableFilter defaultValue="ALL" onChange={onFilter} /> }
      </QuickSearchToolbar>
    </Box>
  );
}
