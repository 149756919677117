import { Skeleton } from "@mui/material";

export interface FallbackCellProps {
    loading: boolean,
    children: any
}

const FallbackCell = ({loading, children}: FallbackCellProps) =>(
    loading 
        ? <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
        : <>{children}</>
);

export default FallbackCell;