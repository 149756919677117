import { gql } from '../../../../../__generated__';

export const PEOPLE_FOR_LINK__STUDENT_FRAGMENT = gql(`
    fragment PeopleForLink__StudentFragment on StudentsConnection {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                firstName
                lastName
                dob
                references
            }
        }
        totalCount
    }
`)