import { z } from "zod";
import { FormSubmitCallback } from "../../../components/Form";
import { FieldProps } from "react-final-form";
import { FormBase, FormBaseProps } from "../../../components/Form/FormBase";
import InputField from "../../../components/Form/InputField";
import SelectField from "../../../components/Form/SelectField";
import { useEffect, useState } from "react";

const PERSON_FORM_FIELDS: FieldProps<string, any>[] = [
    {
      name: "firstName",
      label: "First Name",
      render: InputField,
      required: true,
      fullWidth: true,
      grid: {
        xs: 6,
      },
    },
    {
      name: "middleName",
      label: "Middle Name",
      render: InputField,
      required: false,
      fullWidth: true,
      grid: {
        xs: 6,
      },
    },
    {
      name: "lastName",
      label: "Last Name",
      render: InputField,
      required: true,
      fullWidth: true,
      grid: {
        xs: 6,
      },
    },
    {
      name: "roleType",
      label: "User Role / Type",
      render: SelectField,
      required: true,
      fullWidth: true,
      grid: {
        xs: 6,
      }
    },
    {
      name: "jobTitle",
      label: "Job Title",
      render: InputField,
      required: false,
      fullWidth: true,
      grid: {
        xs: 12,
      },
    },
    {
      name: "email",
      label: "Email",
      render: InputField,
      required: true,
      fullWidth: true,
      grid: {
        xs: 12,
      },
    }
  ];

const roleTypeSchema = z.union([z.literal("ADMIN"), z.literal("STANDARD"), z.null(), z.undefined()]);

const performValidationSchema = z.object({
  firstName: z.string().trim().nonempty().max(255),
  middleName: z.string().trim().max(255).nullish(),
  lastName: z.string().trim().nonempty().max(255),
  jobTitle: z.string().trim().max(255).nullish(),
  email: z.string().nonempty().email(),
  roleType: roleTypeSchema.default("STANDARD")
});

export type PersonFormSchema = z.infer<typeof performValidationSchema>;

type FormState = {
    fields: FieldProps<string, any>[]
}

export type PersonRole = { 
    name: string,
    value: string,
    default: boolean
 }

export type PersonFormProps = {
    roles: PersonRole[]
    rolesLoading: boolean,
    initialValues: PersonFormSchema | null | undefined,
    onSubmit: (values: PersonFormSchema) =>  Promise<ReturnType<FormSubmitCallback>>
    onCancel: () => void
} & Pick<FormBaseProps<PersonFormSchema>, "initialValues" | "readonly">

export default function PersonForm({ roles, rolesLoading = true, initialValues, readonly, onSubmit, onCancel }: PersonFormProps) {

    const [formState, setFormState] = useState<FormState>({
        fields: PERSON_FORM_FIELDS
    });

    useEffect(() => {
        const fields = PERSON_FORM_FIELDS.map((field) => {
            if (field.name !== "roleType") return field;
            
            return {
                ...field,
                loading: rolesLoading,
                options: roles.map((role) => ({
                    label: role.name,
                    value: role.value,
                    isDefault: role.default,
                })),
            }
        });

        setFormState(prevState => ({
            ...prevState,
            fields
        }))
    }, [roles]);

    const EditForm = FormBase<PersonFormSchema>({
        fields: formState.fields,
        initialValues: initialValues ?? {},
        validationSchema: performValidationSchema,
        buttonSubmitLabel: "Save",
        buttonCancelLabel: "Cancel",
        readonly,
        onSubmit: onSubmit,
        onCancel: onCancel
      })

      return EditForm;
}