import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Button, Fade, Grid } from "@mui/material";
import { Field, Form } from "react-final-form";
import { z } from "zod";
import InputField from "../../../../../components/Form/InputField";

const noteSchema = z.object({
    note: z.string().trim().nonempty().max(2000),
});

type NoteFormProps = z.infer<typeof noteSchema>;

const formValidator = <T extends z.ZodType<any, any>>(schema: T) => (values: any) => {
    try {
      schema.parse(values);
    } catch (err) {
      return (err as z.ZodError).formErrors.fieldErrors;
    }
  };

export default function NoteForm({ onCancel, onSubmit }: { onCancel: () => void, onSubmit: (values: NoteFormProps) => void }) {

    return (
        <Form onSubmit={(values: NoteFormProps) => onSubmit(values)} validate={formValidator(noteSchema)} 
            render={({ form, submitting, submitError, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Field name="note" render={(fieldProps) => (
                                <InputField
                                    label="Note"
                                    rows={5}
                                    multiline
                                    fullWidth
                                    {...fieldProps} />
                            )} />
                        </Grid>
                        {submitError && (
                            <Fade appear in timeout={250}>
                                <Grid item xs={12}>
                                    <Alert variant="filled" severity="error">
                                        { submitError }
                                    </Alert>
                                </Grid>
                            </Fade>
                        )}
                        <Grid container item sx={{ marginTop: 2 }}>
                            <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                                <Button variant="contained" onClick={onCancel}>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                                <LoadingButton
                                    type="submit"
                                    loading={submitting}
                                    variant="contained"
                                >
                                    Submit
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            )} 
        />
    );
}