import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Field, FieldProps, Form } from "react-final-form";
import format from "date-fns/format";
import { z } from "zod";

import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { MANAGE_USER_FIELDS } from "../../../../Manage/Users/constants";
import InputField from "../../../../../components/Form/InputField";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ROLES } from "../../../../../features/graphql/query/roles/getRoles";
import { AddPersonMutation, AddPersonMutationVariables, GetRolesQuery, PersonRole } from "../../../../../__generated__/graphql";
import { ADD_PERSON_MUTATION } from "../../../../../features/graphql/mutation/person/addPerson";
import { useSlideInContext } from "../context/SlideInProvider";
import { SelectOption, getDefaultValue } from "../../../../../components/Form/SelectField/SelectField";
import { PEOPLE_FOR_LINK_QUERY } from "../../../../../features/graphql/query/person/getPeopleForLink";
import { ApiValidationError, mapApiFieldValidationErrors } from "../../../../../components/Form";

const manageDetailsSchema = z.object({
  firstName: z.string().trim().nonempty().max(255),
  middleName: z.string().trim().max(255).nullish(),
  lastName: z.string().trim().nonempty().max(255),
  jobTitle: z.string().trim().max(255).nullish(),
  email: z.string().nonempty().email(),
  roleType: z.enum(["ADMIN", "STANDARD"]),
  startDate: z.coerce.date()
});

type EditPersonSchema = z.infer<typeof manageDetailsSchema>;

const formValidator = <T extends z.ZodType<any, any>>(schema: T) => (values: any) => {
  try {
    schema.parse(values);
  } catch (err) {
    return (err as z.ZodError).formErrors.fieldErrors;
  }
};

const mapSelectOptions = (options: any[]): SelectOption[] => options.map(x => ({
  label: x.name,
  value: x.normalizedName,
  isDefault: x.default,
})) ?? []

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms)); 

const EditUser = () => {
  const [fields, setFields] = useState<FieldProps<string, any>>();
  const [roleOptions, setRoleOptions] = useState<SelectOption[]>([]);
  const { toggleDrawer } = useSlideInContext();
  const { data } = useQuery<GetRolesQuery>(GET_ROLES);
  const [addPerson] = useMutation<AddPersonMutation, AddPersonMutationVariables>(ADD_PERSON_MUTATION, {
    refetchQueries: [PEOPLE_FOR_LINK_QUERY]
  })

  useEffect(() => {
    if (!data?.roles) return;

    var options = mapSelectOptions(data?.roles ?? []);

    setFields(() => MANAGE_USER_FIELDS.map((field) => {
        if (field.name !== "roleType") return field;

        return {
          ...field,
          options
        };
      }));

      setRoleOptions(options);
  }, [MANAGE_USER_FIELDS, data?.roles]);

  const createNewUser = async (values: EditPersonSchema) => { 

    const response = await addPerson({
      variables: {
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        role: values.roleType as PersonRole,
        email: values.email,
        startDate: values.startDate
      },
      onError(error, clientOptions) {
        console.log('Query error: ', error, clientOptions);
      }
    });
    
    // If type of "ValidationError" exists in the errors payload field
    // map errors to dicitonary and return. Final form will then 
    // render the errors to the each stated field
    // Note: must match form definition
    if (response.data?.addPerson.errors) {
      const validationError = response.data.addPerson.errors
        .find(e => e.__typename === "ValidationError") as ApiValidationError;

      const fieldErrors = validationError 
        ? mapApiFieldValidationErrors(validationError)
        : null;

      return fieldErrors;
    }

    toggleDrawer();
  };

  return (
    <div style={{ color: "#fff", paddingTop: "16px" }}>
      <Form
        initialValues={{ 
          roleType: getDefaultValue(roleOptions), 
          startDate: format(new Date(), 'yyyy-MM-dd') 
        }}
        onSubmit={createNewUser}
        validate={formValidator(manageDetailsSchema)}
        render={({ handleSubmit, form, submitting }) => ( 
          <form onSubmit={handleSubmit}>
            <Grid style={{ marginTop: 0 }} container spacing={4} rowSpacing={4}>
              {fields && fields.map((field: FieldProps<string, any>, i: number) => (
                <Grid
                  key={`add-user-field${i + 20}`}
                  item
                  xs={field.grid.xs}
                >
                  <Field {...field} />
                </Grid>
              ))}
              <Grid item xs={6}>
                <Field
                  name="startDate"
                  label="Start Date"
                  type="date"
                  render={InputField}
                  InputLabelProps={{ shrink: true }}
                  required
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <LoadingButton
                  loading={submitting}
                  disableElevation
                  variant="contained"
                  size="large"
                  type="submit"
                  onClick={() => form.submit()}
                  sx={{
                    backgroundColor: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.light",
                    },
                  }}
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </div>
  );
};

export default EditUser;
