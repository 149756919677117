import { ReactNode, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { useManageModal } from "../../../../Manage/components/ManageModal";
import { useSlideInContext } from "../context/SlideInProvider";
import { usePlayerLobby } from "../../../../../context/playerContext/PlayerDataProvider";
import usePersonAuthStore, { decodedToken } from "../../../personAuthStore";
import SendIcon from '@mui/icons-material/Send';
import EmailForm, { EmailFormProps, EmailFormSchema } from "./EmailForm";
import { useMutation } from "@apollo/client";
import { EditStudentParentEmailMutation, EditStudentParentEmailMutationVariables, Student } from "../../../../../__generated__/graphql";
import { EDIT_STUDENT_PARENT_EMAIL } from "../../../../../features/graphql/mutation/students/editStudentParentEmailMutation";

type PARENT_EMAIL_ACTION = "general" | "none";

function LaunchButton({ action: type, currentAction: action, onAction, width = 180, disabled, children }: { 
  action: PARENT_EMAIL_ACTION, 
  currentAction: PARENT_EMAIL_ACTION | null, 
  onAction: (action: PARENT_EMAIL_ACTION) => {}, children: ReactNode,
  width?: number,
  disabled?: boolean
}) {
  return (
    <LoadingButton
      sx={{
        backgroundColor: "primary.main",
        "&:hover": {
          backgroundColor: "primary.light",
        },
        width,
        marginTop: "16px",
        padding: 1
      }}
      type="submit"
      variant="contained"
      loading={type === action}
      loadingPosition="end"
      disabled={disabled}
      endIcon={<SendIcon />}
      onClick={() => onAction(type)}
    >
      { children }
    </LoadingButton>
  )
}

function EmailFormWrapper ({ onSubmit }: EmailFormProps) {
    return (
        <Box>
            <Typography>There's no parent email for this child, please provide an email.</Typography>
            <EmailForm onSubmit={onSubmit} />
        </Box>
    )
}

export default function LaunchOptions() {
  const { openModal } = useManageModal();
  const { disableClosingDrawer, toggleDrawer } = useSlideInContext();
  const { playerData, updatePlayer } = usePlayerLobby();
  const [ launchAction, setLaunchAction ] = useState<PARENT_EMAIL_ACTION | null>(null);

  const accessToken = usePersonAuthStore((state) => state.access_token);

  const [editStudentParentEmail, { data }] = 
    useMutation<EditStudentParentEmailMutation, EditStudentParentEmailMutationVariables>(EDIT_STUDENT_PARENT_EMAIL);

  const handleEmailSubmission = async (values: EmailFormSchema) => {
    console.log('Submitting email', values.email, playerData);

    const response = await editStudentParentEmail({
        variables: {
            id: playerData.id,
            parentEmail: values.email
        }
    });

    // Update the student (player) context object with the new parent email value
    // but do not overwrite exsiting values
    updatePlayer({
        parentEmail: values.email
    } as Student);

    console.error(response.errors?.at(0)?.message);
  }

  const handleLaunch = async (action: PARENT_EMAIL_ACTION) => {

    // Prevent accidental closing of the application drawer
    disableClosingDrawer(true);

    setLaunchAction(action);

    const { pid, lid } = decodedToken(accessToken ?? '');
    const response = await fetch(`${window.__env.API_URL}/api/parent/send/general-information`, {
        method: "post",
        headers: {
          Pid: pid,
          Lid: lid,
          "Content-Type": "application/json",
          authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          personId: pid,
          studentId: playerData.id,
        }),
    })

    if (response.status >= 400) {
        showFailureMessage();
        disableClosingDrawer(false)
        setLaunchAction(null);
        return;
    }

    showSuccessMessage();

    disableClosingDrawer(false)
    setLaunchAction(null);
  };

  const showSuccessMessage = () => {
    toggleDrawer();
    disableClosingDrawer(false);
    openModal({ type: "PARENT_SEND_GENERAL_INFORMATION_SUCCESS", eventType: 'DISABLE_BACKDROP', cancelButtonLabel: "Close" });
  };

  const showFailureMessage = () => {
    toggleDrawer();
    disableClosingDrawer(false);
    openModal({ type: "GENERAL_ERROR", eventType: 'DISABLE_BACKDROP', cancelButtonLabel: "Close" });
  };

  if (!playerData?.parentEmail)
    return <EmailFormWrapper onSubmit={handleEmailSubmission} />

  return (
    <Grid
      container
      spacing={1}
      direction="column"
      alignItems="center"
      sx={{ padding: "36px 16px", textAlign: "center" }}
    >
      <Grid item xs={12} direction="column" alignItems="center">
        <Typography sx={{ marginBottom: 2 }}>
          Email on file is <strong>{ playerData.parentEmail }</strong>
        </Typography>
      </Grid>
      <Grid item xs={12} direction="column" alignItems="center">
        <Typography>
            What type of information would you like to send?
        </Typography>
        <LaunchButton
            width={210}
            action="general"
            currentAction={launchAction}
            onAction={handleLaunch}
        >
            General Information
        </LaunchButton>
        <LaunchButton
            width={210}
            action="none"
            currentAction={null}
            onAction={async (action) => {}}
            disabled
        >
            Coming Soon Parent 1 Report
        </LaunchButton>
        <LaunchButton
            width={210}
            action="none"
            currentAction={null}
            onAction={async (action) => {}}
            disabled
        >
            Coming Soon Parent 2 Report
        </LaunchButton>
      </Grid>
    </Grid>
  );
}
