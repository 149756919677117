import { useMutation } from "@apollo/client";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { gql } from "../../../../__generated__";
import { DEFAULT_PAGE_SIZE, PERSON_STUDENTS_QUERY } from "../PersonTableLinkPreview";

const PERSON_STUDENT_ASSIGN_MUTATION = gql(/* GraphQL */`
    mutation PersonStudentAssign($personId: UUID!, $studentIds: [UUID!]!) {
        __typename
        assignPersonStudents(input: { personId: $personId, studentIds: $studentIds }) {
            person {
                id
            }
            errors {
                ... on ValidationError {
                    message
                    errors {
                        fieldName
                        messages
                    }
                }
            }
        }
    }
`)

const PERSON_STUDENT_UNASSIGN_MUTATION = gql(/* GraphQL */`
    mutation PersonStudentUnassign($personId: UUID!, $studentIds: [UUID!]!) {
        unassignPersonStudents(input: { personId: $personId, studentIds: $studentIds }) {
            __typename
            person {
                id
            }
            errors {
                ... on ValidationError {
                    message
                    errors {
                        fieldName
                        messages
                    }
                }
            }
        }
    }
`)

const usePersonStudentAssignment = (personId: string) => {
    const [assignPersonStudents] = useMutation(PERSON_STUDENT_ASSIGN_MUTATION);
    const [unassignPersonStudents] = useMutation(PERSON_STUDENT_UNASSIGN_MUTATION, {
        refetchQueries: [{
            query: PERSON_STUDENTS_QUERY,
            variables: { personId, first: DEFAULT_PAGE_SIZE, query: "", after: null }
          }]
    });

    const save = async (assignments: GridRowSelectionModel, unassignments: GridRowSelectionModel) => {
        
        // Exit early if there are no changes to be made
        if (assignments.length === 0 && unassignments.length === 0) {
            console.trace("Attempting to save changes but no assigment or unassigment IDs are provided");
            return;
        }

        if (assignments.length > 0) {
            if (process.env.NODE_ENV === "development")
                console.trace(`Attempting assigning students to person ${personId}`, assignments);

            const response = await assignPersonStudents({
                variables: {
                    personId,
                    studentIds: assignments
                }
            });

            if (process.env.NODE_ENV === "development")
                console.trace(`Successfully assigned students to person ${personId}`);
            
            if (response.errors?.length ?? 0 > 0)
                console.error("Student Assignment Failure", response.errors);
        }
        
        if (unassignments.length > 0) {
            if (process.env.NODE_ENV === "development")
                console.trace(`Attempting unassigning students for person ${personId}`, assignments);

            const response = await unassignPersonStudents({
                variables: {
                    personId,
                    studentIds: unassignments
                }
            });
            
            if (process.env.NODE_ENV === "development")
                console.trace(`Successfully unassigned students to person ${personId}`);

            if (response.errors?.length ?? 0 > 0)
                console.error("Student Unassignment Failure", response.errors);
        }
    }

    return { save };
}

export default usePersonStudentAssignment;