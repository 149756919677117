import { useMutation, useQuery } from "@apollo/client";
import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

import { EditPersonMutation, EditPersonMutationVariables, GetRolesQuery } from "../../../__generated__/graphql";
import { GET_ROLES } from "../../../features/graphql/query/roles/getRoles";
import LinkItemsHeader from "../components/ManageDetails/components/LinkItems/LinkItemsHeader";
import { PersonDataType, useSelectedPerson } from "./SelectedPersonProvider";
import { ApiValidationError, FormSubmitCallback, mapApiFieldValidationErrors } from "../../../components/Form";
import { EDIT_PERSON_MUTATION } from "../../../features/graphql/mutation/person/editPerson";
import Header from "../components/Header/Header";
import { useManageModal } from "../components/ManageModal";
import PersonForm, { PersonFormSchema, PersonRole } from './PersonForm';
import PersonTableLinkPreview from "./PersonTableLinkPreview";
import usePersonStudentAssignment from "./hooks/usePersonStudentAssignment";

import { useExpirePerson } from '../hooks/useExpirePerson';

interface ManageDetailsProps {
  onBack: () => void,
  onLink: (id: string) => void,
}

type RouteParams = {
  pid?: string
}

export default function PersonManagePage({
  onBack,
  onLink,
}: ManageDetailsProps) {

  const params  = useParams<RouteParams>();
  
  const { person, setSelectedPerson } = useSelectedPerson(params.pid);

  const { expirePerson } = useExpirePerson();

  const { openModal, closeModal } = useManageModal();

  const { save } = usePersonStudentAssignment(params.pid ?? '');

  const { loading, data: roleDocument } = useQuery<GetRolesQuery>(GET_ROLES);

  const [editPerson] = useMutation<EditPersonMutation, EditPersonMutationVariables>(EDIT_PERSON_MUTATION);

  const [personRoles, setPersonRoles] = useState<PersonRole[] | null | undefined>([]);

  const [removeRowId, setRemoveRowId] = useState<string | null>(null);

  useEffect(() => {
    const roles = roleDocument?.roles.map((role): PersonRole => ({
      name: role.name,
      value: role.normalizedName,
      default: role.default
    }));

    setPersonRoles(roles)
  }, [loading, roleDocument]);


  const handleEditPersonSubmission: FormSubmitCallback<PersonFormSchema> = async (values): 
    Promise<ReturnType<FormSubmitCallback>> => {
    
    const response = await editPerson({
      variables: {
        input: {
          id: person?.id,
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          email: values.email,
          jobTitle: values.jobTitle,
          userRole: values.roleType,
          startDate: person?.startDate
        }
      }
    });

    if (response.data?.editPerson.errors) {

      const validationError = response.data.editPerson.errors
        .find(e => e.__typename === "ValidationError") as ApiValidationError;

      const fieldErrors = validationError 
        ? mapApiFieldValidationErrors(validationError)
        : null;

        return fieldErrors;
    }


    const replace = {
      ...person,
      // SO dirty. Essientially it's the same data, but the type is different.
      // People should look into better, shared type management with fragment
      ...response.data?.editPerson.person
    } as PersonDataType

    setSelectedPerson(replace);

    console.log('Submitting the details form', values);
  }

  const handleExpire = async () => {
    if (!person?.expired) {
      openModal({ 
        type: 'EXPIRE_PERSON', 
        confirmButtonType: 'WARNING',
        confirmButtonLabel: 'Expire',
        onConfirm: async () => {
          openModal(state => ({
              ...state,
              status: 'SAVING'
          }))

          await expirePerson(person?.id)

          closeModal();
        }
      })
    } else {
      openModal({ 
        type: 'REACTIVATE', 
      })
    }
  }
  
  const handleUnassignment = async (id: string) => {
    setRemoveRowId(id);

    openModal({
      type: 'REMOVE_ASSIGNMENT',
      confirmButtonType: 'WARNING',
      confirmButtonLabel: 'Yes',
      onConfirm: async () => {
        openModal(state => ({
          ...state,
          status: 'SAVING'
        }));

        const unassign = [id];
        await save([], unassign);

        setRemoveRowId(null);

        closeModal();
      },
      onCancel: () => {
        setRemoveRowId(null);
      },
    })
  }

  return (
    <Card
      elevation={4}
      style={{
        width: "100%",
        display: "flex",
        padding: "16px",
        height: "auto",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Header
            onBack={onBack}
            title={person 
              ? `Details for ${person?.firstName} ${person?.lastName}`
              : 'Loading...'}
          />
          <PersonForm 
            roles={personRoles ?? []}
            rolesLoading={loading}
            initialValues={{
              firstName: person?.firstName ?? '',
              middleName: person?.middleName,
              lastName: person?.lastName ?? '',
              email: person?.email ?? '',
              jobTitle: person?.jobTitle,
              roleType: person?.role?.normalizedName as "ADMIN" | "STANDARD" | null
            }}
            readonly={person?.expired}
            onSubmit={handleEditPersonSubmission}
            onCancel={onBack}
          />
        </Grid>
        <Grid item xs={6}>
          <LinkItemsHeader 
            entityId={person?.id ?? ''}
            startDate={person?.startDate}
            endDate={person?.endDate}
            expired={person?.expired}
            onExpire={handleExpire} />
          { !person?.expired && (
            <PersonTableLinkPreview
              selectedPerson={person}
              removeRowId={removeRowId}
              onLink={onLink}
              onRemove={handleUnassignment}
              onDisabled={(reason) => {
                openModal({ type: reason })
              }}/>
          ) }
        </Grid>
      </Grid>
    </Card>
  );
}
