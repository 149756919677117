import { GridColDef, GridRowModel } from "@mui/x-data-grid";
import { StudentCustomField } from "../../__generated__/graphql";
import { format, parseISO } from "date-fns";
import { dateOnlyISO8601 } from "../../library/date";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Box, IconButton, Paper } from "@mui/material";
import { TablePaginated, TableToolbar } from "../Manage/components";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { STUDENTS_WITH_LOCATION_DETAILS_QUERY } from "../../features/graphql/query";
import { LoadingWrapper } from "../../components/LoadingWrapper";
import usePersonStore from "../Admin/personStore";

const createCustomField = (customField: any): GridColDef => {
  return {
    field: customField.name ?? "missing",
    headerName: customField.name ?? "missing",
    flex: 1,
    sortable: true,
    editable: false,
    disableColumnMenu: true,
    valueGetter: (params) => {
      const studentCustomField = params.row.studentCustomFields.find((x: StudentCustomField) => x.customField?.id === customField.id)
      return studentCustomField ? studentCustomField.value : null
    },
    renderCell: (params) => {
      const studentCustomField = params.row.studentCustomFields.find(
        (x: StudentCustomField) => x.customField?.id === customField.id
      ) as StudentCustomField;

      return studentCustomField ? <>{studentCustomField.value}</> : null;
    },
  };
};

const mapEdgesToTable = (data: any) => {
  return {
    ...data.node,
    name: `${data.node.firstName} ${data.node.lastName}`,
    dob: format(parseISO(dateOnlyISO8601(data.node.dob)), "MM/dd/yyyy"),
    student: data.node
  };
};

const DEFAULT_PAGE_SIZE = 100;

const TABLE_COLUMNS: GridColDef[] = [
  { field: "firstName", headerName: "First Name", flex: 1, sortable: true },
  { field: "lastName", headerName: "Last Name", flex: 1, sortable: true },
  // { field: "name", headerName: "Fullname", flex: 1, sortable: false },
  { field: "dob", headerName: "Date of Birth", flex: 1, sortable: true },
];

type SelectedAction = 'LAUNCH' | 'SESSIONS';

type StudentsTableProps = {
  onSelectedStudent: (record: any, action: SelectedAction) => void
}

export default function StudentsTable({
  onSelectedStudent
}: StudentsTableProps) {

  const defaultLocation = usePersonStore(state => state.company.defaultLocation);

  const { loading, data, fetchMore, error } = useQuery(STUDENTS_WITH_LOCATION_DETAILS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      query: "",
      first: 100,
    },
  });

  const [pageTableState, setPageTableState] = useState<{
    columns: GridColDef[];
    rows: GridRowModel[];
  }>({
    columns: [],
    rows: [],
  });
  useEffect(() => {
    setPageTableState((prevState) => {
      const columns = [...TABLE_COLUMNS];

      data?.location.customFields.forEach((field: any) => {
        columns.push(createCustomField(field));
      });

      columns.push({
        field: "launch",
        headerName: "Launch",
        align: 'center',
        sortable: false,
        resizable: false,
        editable: false,
        disableColumnMenu: true,
        width: 100,
        renderHeader(params) {
          return <Box component="div" sx={{ width: 100, textAlign: 'center' }}>{params.colDef.headerName}</Box>
        },
        renderCell: (params) => {
          return (
            <IconButton onClick={() => onSelectedStudent(params.row.student, 'LAUNCH')}>
              <OpenInNewIcon />
            </IconButton>
          );
        },
      });

      columns.push({
        field: "details",
        headerName: "Details",
        align: 'center',
        sortable: false,
        resizable: false,
        editable: false,
        disableColumnMenu: true,
        width: 100,

        renderHeader(params) {
          return <Box component="div" sx={{ width: 100, textAlign: 'center' }}>{params.colDef.headerName}</Box>
        },
        renderCell: (params) => {
          const student = params.row.student;
          return (
            <IconButton onClick={() => onSelectedStudent(student, 'SESSIONS')}>
              <FileCopyIcon />
            </IconButton>
          );
        },
      });

      const rows = data?.students?.edges?.map(mapEdgesToTable) ?? [];

      return {
        ...prevState,
        columns,
        rows
      };
    });
  }, [loading, data]);
  return (
    <LoadingWrapper loading={(loading && !data) || !Boolean(defaultLocation)}>
      <Paper>
        <TablePaginated
          renderToolbar={({ searchQuery, onSearch, onSearchClear }) => (
            <TableToolbar
              title="Students"
              subTitle={`@${defaultLocation?.name}`}
              searchQuery={searchQuery ?? ""}
              onSearch={onSearch}
              onSearchClear={onSearchClear}
            />
          )}
          pageInfo={data?.students?.pageInfo}
          pageSize={DEFAULT_PAGE_SIZE}
          columns={pageTableState.columns}
          rows={pageTableState.rows}
          totalRows={data?.students?.totalCount ?? 0}
          loading={loading}
          onFetchMore={async ({ query }) => {
            await fetchMore({
              variables: {
                query,
              },
            });
          }}
          disableColumnSelector
        />
      </Paper>
    </LoadingWrapper>
  );
};

