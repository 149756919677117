import { GridRowModel } from "@mui/x-data-grid";
import { useState, useEffect } from "react";

enum FilterByTypes {
  ALL_RECORDS = "All Records",
  ACTIVE_ONLY = "Active Only",
  EXPIRED_ONLY = "Expired Only",
}

const useFilterBy = (
  data: GridRowModel[],
  setData: React.Dispatch<React.SetStateAction<any[]>>
) => {
  const [filterBy, setFilterBy] = useState<string>(FilterByTypes.ALL_RECORDS);

  useEffect(() => {
    switch (filterBy) {
      case FilterByTypes.ALL_RECORDS: {
        setData([...data]);
        break;
      }
      case FilterByTypes.ACTIVE_ONLY: {
        setData([...data].filter((value) => !value.expired));
        break;
      }
      case FilterByTypes.EXPIRED_ONLY: {
        setData([...data].filter((value) => value.expired));
        break;
      }
      default:
        break;
    }
  }, [filterBy  ]);

  return { filterBy, setFilterBy };
};

export { useFilterBy, FilterByTypes };
