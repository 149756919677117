import { Container, Fade } from "@mui/material";
import { SlideInContextProvider } from "../Admin/components/SlideIn/context/SlideInProvider";
import SlideIn from "../Admin/components/SlideIn";
import AppBar from "../Admin/components/AppBar";
import { Navigate, Route, Routes, useNavigate } from "react-router";
import { DashboardPage } from "./DashboardPage";
import ResultsGrid from "../Admin/components/ResultsGrid";
import HistoryTable from "../Admin/components/ResultsGrid/HistoryTable";
import { WorkListContextProvider } from "../../context/workLists";

const IndexPageRoutes = () => {

    const navigate = useNavigate();

    return (
        <Routes>
            <Route index element={<DashboardPage />} />
            <Route path="/student/:studentId/sessions" element={(
                <ResultsGrid 
                activeStudent={null}
                onSelectedSession={(studentId: string, sessionId: string) => {
                    navigate(`student/${studentId}/session/${sessionId}/notes`)
                }}
                onBack={() => {
                    navigate('.');
                }} />
            )} />
            <Route path="student/:studentId/session/:sessionId/notes" element={(
                <HistoryTable
                onBack={() => {
                    navigate(-1);
                }} />
            )} />
            <Route path="*" element={<Navigate to="." replace />} />
        </Routes>
    );
}


export default function IndexPage() {
    return (
        <WorkListContextProvider>
            <SlideInContextProvider>
                <AppBar />
                <SlideIn />
                <Fade appear in timeout={500}>
                    <Container maxWidth="xl">
                        <IndexPageRoutes />
                    </Container>
                </Fade>
            </SlideInContextProvider>
        </WorkListContextProvider>
    )
}