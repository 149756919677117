import PersonTable from "./PersonTable";
import { useSelectedPerson } from "./SelectedPersonProvider";
import { PEOPLE_FOR_LINK_QUERY } from "../../../features/graphql/query/person/getPeopleForLink";
import { useQuery } from "@apollo/client";
import { useSlideInContext } from "../../Admin/components/SlideIn/context/SlideInProvider";
import { EDIT_USER } from "../../Admin/components/SlideIn/context/contentTypes";
import { useManageModal } from "../components/ManageModal";
import { useExpirePerson } from "../hooks/useExpirePerson";
import { useNavigate } from "react-router";

interface PeoplePageProps {
    onBack: () => void
}

const PeoplePage = ({ onBack }: PeoplePageProps) => {
    const { loading, data } = useQuery(PEOPLE_FOR_LINK_QUERY);
    
    const { handleContent } = useSlideInContext();

    const { setSelectedPerson } = useSelectedPerson();

    const { openModal, closeModal } = useManageModal();

    const { expirePerson } = useExpirePerson();

    const navigate = useNavigate();

    const handleNewPerson = () => {
        handleContent(EDIT_USER);
    };

    return (
        <PersonTable 
            data={data}
            loading={loading}
            onExpire={(person) => {
                if (!person?.expired) {
                    return openModal({ 
                        type: 'EXPIRE_PERSON', 
                        confirmButtonType: 'WARNING',
                        confirmButtonLabel: 'Expire',
                        onConfirm: async () => {
                            openModal(state => ({
                                ...state,
                                status: 'SAVING'
                            }))
                    
                            await expirePerson(person?.id)
                
                            closeModal();
                        }
                    })
                }
                    
                openModal({ 
                    type: 'REACTIVATE', 
                })  
            }}
            onDetail={(person) => {
                setSelectedPerson(person);
                navigate(`/manage/users/details/${person?.id}`)
            }}
            onAddNew={handleNewPerson}
            onBack={onBack} 
        />
    )
}

export default PeoplePage;