import React, { useEffect, useState } from "react";
import StudentForm, {
  CustomField,
  StudentFormSchema,
} from "../../../../Manage/Students/StudentForm";
import { ADD_STUDENT } from "../../../../../features/graphql/mutation/students/addStudent";
import { useMutation, useQuery, gql } from "@apollo/client";
import {
  ApiValidationError,
  FormSubmitCallback,
  mapApiFieldValidationErrors,
} from "../../../../../components/Form";
import { useSlideInContext } from "../context/SlideInProvider";
import {
  AddStudentMutation,
  AddStudentMutationVariables,
  CustomFieldInput,
  LocationsQuery,
  LocationsQueryVariables,
} from "../../../../../__generated__/graphql";
import { STUDENTS_PAGE_QUERY } from "../../../../Manage/Students/StudentsPage";
import usePersonStore from "../../../personStore";

const LOCATION_QUERY = gql(`
  query Locations {
    locations {
      __typename
      id
      customFields {
        __typename
        id
        type
        name
      }
    }
  }
`);

export default function AddStudent() {
  const [addStudent] = useMutation<AddStudentMutation, AddStudentMutationVariables>(ADD_STUDENT, {
    refetchQueries: [STUDENTS_PAGE_QUERY],
  });

  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const { toggleDrawer } = useSlideInContext();
  const { loading, data } = useQuery<LocationsQuery, LocationsQueryVariables>(LOCATION_QUERY);
  const defaultLocation = usePersonStore((state) => state.company.defaultLocation);

  useEffect(() => {
    const _location = data?.locations.find((location) => location.id === defaultLocation?.id)
    const customFields: CustomField[] = _location?.customFields.map((field): CustomField => ({
      id: field.id,
      name: field.name ?? "",
      type: field.type ?? ""
    })) ?? [];
    // const customFields: CustomField[] =
    //   data?.locations[0].customFields.map((field): CustomField => ({
    //     id: field.id,
    //     name: field.name ?? "",
    //     type: field.type ?? "",
    //   })) ?? [];

    setCustomFields(customFields);
  }, [loading, data]);

  const handleEditPersonSubmission: FormSubmitCallback<StudentFormSchema> = async (
    values
  ): Promise<ReturnType<FormSubmitCallback>> => {
    const customFields = Object.keys(values.customFields ?? {})
      .filter((key) => values?.customFields?.[key] ?? null)
      .map((key) => {
        // Shouldn't be null undefined and nulls are filtered out
        const customField = values.customFields![key];
        return {
          id: customField.id,
          value: customField.value,
        } as CustomFieldInput;
      });

    const input = {
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      dob: values.dob,
      postalCode: values.postalCode,
      parentEmail: values.parentEmail,
      parentPhone: values.parentPhone,
      customFields: customFields ?? [],
    };

    const response = await addStudent({ variables: { input } });

    if (response.data?.addStudent.errors) {
      const validationError = response.data.addStudent.errors.find(
        e => e.__typename === "ValidationError"
      ) as ApiValidationError;

      const fieldErrors = validationError
        ? mapApiFieldValidationErrors(validationError)
        : null;

      return fieldErrors;
    } else {
      toggleDrawer();
    }
  };

  return (
    <StudentForm
      onSubmit={handleEditPersonSubmission}
      customFields={customFields}
      initialValues={null}
      onCancel={() => toggleDrawer()}
    />
  );
}
