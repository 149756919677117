export type ApiValidationError = {
    __typename: "ValidationError";
    errors: {
        __typename?: "ErrorDetail" | undefined;
        fieldName: string
        messages: string[]
    }[];
  }

export type ApiUnknownError = {
    __typename: "UnknownError"
    message: string
}

export type ApiFieldValidationError = { [key: string]: string };

/**
 * Map errors from GraphQL API response payload error type ValidationError to dictionary object
 * which contains the field name and error message.
 * 
 * @param error 
 * @returns 
 */
export default function mapApiFieldValidationErrors (error?: ApiValidationError | null): ApiFieldValidationError | null {
    if (!error) return null;

    if (error.__typename !== "ValidationError")
        throw new Error(`The consumed error object must be type of __typename === ValidationError. The type is __typename=${error.__typename}`);

    const errorMap: any = {};
    for (var i = 0; i < error.errors.length; i++) {
        const subError = error.errors[i];
        errorMap[subError.fieldName] = subError.messages[0]
    }

    return errorMap;
}