import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ContentPasteOffOutlined } from "@mui/icons-material"
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LaunchIcon from '@mui/icons-material/Launch';
import SendIcon from '@mui/icons-material/Send';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid';
import { differenceInHours, format, parseISO } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import { Session, Student } from '../../../../__generated__/graphql';
import { Table } from '../../../../components/Table';
import SimpleToolbar from '../../../../components/Table/SimpleToolbar';
import { usePlayerLobby } from '../../../../context/playerContext/PlayerDataProvider';
import { useWorkListContext } from '../../../../context/workLists/WorkListsProvider';
import { markReviewed } from '../../../../features/graphql/mutation/markReviewed/markReviewed';
import { useSlideInContext } from '../SlideIn/context/SlideInProvider';
import { ADD_NOTE, LAUNCH_OPTIONS, SEND_TO_PARENT_OPTIONS } from '../SlideIn/context/contentTypes';
import { useParams } from 'react-router';
import { gql } from '../../../../__generated__';
import { LoadingWrapper } from '../../../../components/LoadingWrapper';
import { NetworkError } from '../../../../components/NetworkError';
import { TagsCell } from './TagCell';
import { MobileStepper } from '@mui/material';

const STUDENT_SESSIONS_QUERY = gql(/* GraphQL */`
  query StudentSessions($studentId: UUID!) {
    student(id: $studentId) {
      __typename
      id
      firstName
      lastName
      parentEmail
      sessions(status: null) {
        __typename
        id
        category
        data
        riskRating
        status
        checkpoint {
          current {
            position
            name
          }
          values
        }
        createdAt
        person {
          firstName
          lastName
        }
        worklist {
          id
          status
        }
        report {
          id
        }
        notes {
          id
          type
          body
          createdAt
          person {
            id
            firstName
            lastName
            role {
              normalizedName
            }
          }
        }
        history {
          data
          createdAt
        }
      }
    }
  }
`);

const sortDate = (order: 'asc' | 'desc') => (a: any, b: any) => {
  const aTime = new Date(a.createdAt).getTime();
  const bTime = new Date(b.createdAt).getTime();
  return order === 'asc' ? aTime - bTime : bTime - aTime;
}

function pascalCaseToHumanReadable(text: string) {
  return text.replace(/([A-Z])/g, ' $1')
    .replace(/^./, function (str) { return str.toLocaleUpperCase(); })
}

function mapTableData(session: Session, student: Student) {

  let _dateFormat = (v: string | null | undefined, f = 'MM/dd/yyyy HH:mm') => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return !v
      ? null
      : v.lastIndexOf('Z') > 0
        ? format(utcToZonedTime(parseISO(v), timeZone), f)
        : format(zonedTimeToUtc(parseISO(v), ''), f);
  };

  // let history = findOneOf([
  //   { key: 'data.label', value: 'AdventureComplete' },
  //   { key: 'data.label', value: 'AuthenticationSuccess' },
  //   { key: 'data.label', value: 'Opened' },
  //   { key: 'data.label', value: 'Initiated' },
  // ], session.history);


  const hours = differenceInHours(new Date(), parseISO(session.createdAt));

  const history = [...session.history].sort(sortDate('desc'))[0];
  const checkpoint = session.checkpoint?.current?.name;
  const historyDateFormated = _dateFormat(history?.createdAt) ?? "";

  let decodedStatus = history?.data?.label === 'Created'
    ? `Created ${session.category} \n ${historyDateFormated}`
    : history?.data?.label === 'Opened'
      ? `Opened \n ${session.category} \n ${historyDateFormated}`
      : history?.data?.label === 'AuthenticationSuccess' || history?.data?.label === 'AvatarSelected'
        ? `Started \n ${historyDateFormated}`
        : session.status === 'Complete' && (['EnvironmentQuestions', 'PersonalQuestions', 'AdventureSelected'].includes(checkpoint ?? ''))
          ? `Partial Report`
          : session.status === 'Complete' && checkpoint === 'AdventureComplete'
            ? 'Full Report'
            : null;

  if (decodedStatus === null) {
    const authHistoryRecord = session.history.find(x => x.data.label === 'AuthenticationSuccess');

    // Encase a history record is not found (should never be the case)
    decodedStatus = authHistoryRecord
      ? `Started \n ${_dateFormat(authHistoryRecord?.createdAt)}`
      : 'Created';
  }

  return {
    id: session.id,
    studentId: student.id,
    category: session.category,
    checkpoint: session.checkpoint,
    data: session.data,
    notes: session.notes,
    worklist: session.worklist,
    report: session.report,
    status: decodedStatus,
    launchedBy: `${session.person.firstName} ${session.person.lastName}`,
    launchedOn: _dateFormat(session.createdAt, 'MM/dd/yyyy'),
  }
}

const modalstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const MarkSessionCell = ({ id, value, handleMark }: { id: string, value: any, handleMark: (id: string) => void }) => {
  if (!value) {
    return (
      < Tooltip title='There is nothing to review' >
        <IconButton>
          <ContentPasteOffOutlined />
        </IconButton>
      </Tooltip >
    )
  }
  if (value === "Unread") {
    return (
      <Tooltip title="Mark Reviewed">
        <IconButton onClick={() => handleMark(id)}>
          <PendingActionsIcon sx={{ color: "#ffc107" }} />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <Tooltip title="Reviewed">
      <IconButton onClick={() => { }} disableFocusRipple sx={{ cursor: "default" }}>
        <CheckCircleIcon sx={{ color: "#4caf50" }} />
      </IconButton>
    </Tooltip>
  )
};

const ViewReportCell = ({ value }: { value: any }) => {
  const onClick = () => {

    // Exit early if no report is found
    // Technically, this function shouldn't be used if no PDF is found
    // because the UI should disable the link/button from being clicked
    if (!Boolean(value.report)) return;

    const link = document.createElement('a');
    link.href = `${window.__env.API_URL}/api/file/download/client/reports/${value.report.id}`;
    link.target = "_blank"

    // Prevent potential memory leak and cleanup ObjectURL
    // resources that are no longer in use
    const linkHandler = () => {
      setTimeout(() => {
        link.removeEventListener('click', linkHandler);
        document.body.removeChild(link);
      }, 300);
    }

    link.addEventListener('click', linkHandler);
    document.body.appendChild(link);
    link.click()
  };

  return (
    // Cursed but converts the value to a boolean and negates it -- love JS :)
    <IconButton disabled={!!!value.report} onClick={onClick}>
      <InsertDriveFileIcon />
    </IconButton>
  );
};

const AddNoteCell = ({ value }: { value: any }) => {
  const { handleContent } = useSlideInContext();
  const { setSession } = useWorkListContext();
  const handleAddNote = () => {
    setSession(value);
    handleContent(ADD_NOTE)
  };
  return (
    <IconButton onClick={handleAddNote}>
      <AddBoxIcon />
    </IconButton>
  );
};

const HistoryCell = ({
  data,
  onClick,
}: {
  data: any;
  onClick: () => void;
}) => {
  return (
    <IconButton disabled={!data?.notes} onClick={onClick}>
      <AccessTimeIcon />
    </IconButton>
  );
};

type ResultTableParams = {
  studentId: string
}

export default function ResultsTable({
  onBack,
  onSelectedSession,
  activeStudent
}: {
  onBack: () => void;
  onSelectedSession: (studentId: string, sessionId: string) => void,
  activeStudent: any
}) {

  const params = useParams<ResultTableParams>();
  console.log('Selected studentID', params.studentId);

  const { handleContent } = useSlideInContext();
  const [MarkReviewed, { loading }] = useMutation(markReviewed);
  const { changePlayer } = usePlayerLobby();
  const { setSession } = useWorkListContext();
  const handleMark = (_id: string) => setMarkedId(_id)

  const [rows, setRows] = useState<any[]>([]);
  const [markedId, setMarkedId] = useState<null | string>(null);

  const { data, loading: studentSessionsLoading, error } = useQuery(STUDENT_SESSIONS_QUERY, {
    fetchPolicy: 'cache-first',
    variables: {
      studentId: params.studentId
    }
  });

  useEffect(() => {
    // Reset player data when results table isn't mounted
    return () => changePlayer(null)
  }, []);

  useEffect(() => {
    if (!data) return;
    const rows = data?.student.sessions.map(s => mapTableData(s as any, data.student as any));
    setRows(rows ?? []);
  }, [data]);

  const handleLaunch = () => {
    changePlayer({ id: params.studentId, parentEmail: data?.student.parentEmail });
    handleContent(LAUNCH_OPTIONS)
  }

  const handleSendToParent = () => {
    changePlayer({ id: params.studentId, parentEmail: data?.student.parentEmail });
    handleContent(SEND_TO_PARENT_OPTIONS)
  }

  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'mark',
        headerName: 'Mark',
        sortable: false,
        resizable: false,
        editable: false,
        disableColumnMenu: true,
        headerAlign: 'center',
        align: 'center',
        width: 100,
        renderCell(params) {
          return (
            <MarkSessionCell
              id={params.row.worklist?.id}
              value={params.row.worklist?.status}
              handleMark={handleMark} />
          )
        }
      },
      {
        field: 'launchedBy',
        headerName: 'Launched By',
        sortable: false,
        resizable: false,
        editable: false,
        disableColumnMenu: true,
        flex: 1
      },
      {
        field: 'launchedOn',
        headerName: 'Launched On',
        sortable: false,
        resizable: false,
        editable: false,
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: false,
        resizable: false,
        editable: false,
        disableColumnMenu: true,
        flex: 2,
        renderCell(params) {
          return <div dangerouslySetInnerHTML={{ __html: params.row?.status.replace('\n', '<br />') }}></div>
        }
      },
      {
        field: 'checkpoint',
        headerName: 'Completed Up To',
        sortable: false,
        resizable: false,
        editable: false,
        disableColumnMenu: true,
        flex: 1.5,
        renderCell(params) {
          const checkpoint = params.row?.checkpoint
          return (
            <Box>
              <Box sx={{ marginBottom: .2 }}>
                {pascalCaseToHumanReadable(checkpoint?.current.name)}
              </Box>
              <Box display="flex" sx={{ width: '100%' }}>
                <MobileStepper position='static' variant="dots" activeStep={checkpoint?.current.position ?? 0} steps={(checkpoint?.values.length ?? 1) - 1}
                  sx={(theme) => console.log(theme.palette)! || ({
                    background: 'transparent',
                    padding: 0,
                    margin: 0,
                    flexGrow: 1,
                    '& .MuiMobileStepper-dotActive': {
                      background: theme.palette.info.light
                    }
                  })} backButton={undefined} nextButton={undefined} />
              </Box>
            </Box>
          )
        }
      },
      {
        field: 'flag',
        headerName: 'Brief Summary',
        sortable: false,
        resizable: false,
        editable: false,
        disableColumnMenu: true,
        headerAlign: 'left',
        align: 'left',
        flex: 3,
        renderCell(params) {
          return (
            <TagsCell flags={params.row?.data
              ? JSON.parse(params.row?.data)?.tags
              : null} status={params.row?.worklist?.status} />
          )
        }
      },
      {
        field: 'view',
        headerName: 'View / Download',
        sortable: false,
        resizable: false,
        editable: false,
        disableColumnMenu: true,
        headerAlign: 'center',
        align: 'center',
        width: 150,
        renderCell(params) {
          return (
            <ViewReportCell value={params.row} />
          )
        }
      },
      {
        field: 'note',
        headerName: 'Add Note',
        sortable: false,
        resizable: false,
        editable: false,
        disableColumnMenu: true,
        headerAlign: 'center',
        align: 'center',
        width: 100,
        renderCell(params) {
          return (
            <AddNoteCell value={params.row} />
          )
        }
      },
      {
        field: 'history',
        headerName: 'History',
        sortable: false,
        resizable: false,
        editable: false,
        disableColumnMenu: true,
        headerAlign: 'center',
        align: 'center',
        width: 100,
        renderCell(params) {
          return (
            <HistoryCell
              data={params.row}
              onClick={() => {
                setSession(params.row);
                onSelectedSession(params.row.studentId, params.row.id)
              }}
            />
          )
        }
      },
    ]
  }, [onSelectedSession, handleMark]);

  if (error?.networkError)
    return <NetworkError error={true} loading={false} />

  return (
    <LoadingWrapper loading={studentSessionsLoading && !data} sx={{ marginTop: 4 }}>
      <Box component="div" sx={{ background: '#fff', paddingTop: 1, marginTop: 4, marginBottom: 8 }}>
        <Table
          loading={studentSessionsLoading}
          renderToolbar={() => {
            return (
              <Box component="div" sx={{ paddingBottom: 1 }}>
                <SimpleToolbar title={`Results for ${data?.student.firstName} ${data?.student.lastName}`} onBack={onBack} rightSlot={() => (
                  <Box component="div" sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      color="secondary"
                      variant="text"
                      startIcon={<LaunchIcon />}
                      onClick={() => handleLaunch()}
                      sx={{ paddingTop: 1.5, paddingBottom: 1.5, paddingLeft: 3, paddingRight: 3 }}
                    >
                      Launch
                    </Button>
                    <Button
                      color="secondary"
                      variant="text"
                      startIcon={<SendIcon />}
                      onClick={() => handleSendToParent()}
                      sx={{ paddingTop: 1.5, paddingBottom: 1.5, paddingLeft: 3, paddingRight: 3 }}
                    >
                      Information for Parents
                    </Button>
                  </Box>
                )} />
              </Box>
            )
          }}
          columns={columns}
          rows={rows}
          pageSize={10}
          disableBoxShadow={false}
          autoHeight />
      </Box>

      <Modal
        open={!!markedId}
        onClose={() => setMarkedId(null)}
      >
        <Box sx={modalstyle} component="div">
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: "center" }}>
            Mark this session as reviewed?
          </Typography>
          <Box component="div" sx={{ paddingTop: "16px", display: "flex", justifyContent: "space-between", width: "100%" }}>
            <Button variant="contained" disableElevation color="inherit" onClick={() => setMarkedId(null)}>
              Cancel
            </Button>
            {loading ?
              (
                <LoadingButton loading variant="contained">
                  Confirm
                </LoadingButton>
              )
              : (<Button variant="contained" disableElevation onClick={() => {
                MarkReviewed({
                  variables: {
                    id: markedId
                  },
                  update: (cache, { data: worklist }) => {
                    const currentSessions = cache.readQuery({
                      query: STUDENT_SESSIONS_QUERY, variables: {
                        studentId: params.studentId
                      }
                    });

                    const nextSessions = {
                      student: {
                        ...currentSessions?.student,
                        sessions: currentSessions?.student.sessions.map(session => session.id === worklist.id
                          ? ({
                            ...session,
                            worklist: {
                              ...session.worklist,
                              ...worklist
                            }
                          })
                          : session
                        )
                      }
                    }

                    cache.writeQuery({
                      query: STUDENT_SESSIONS_QUERY,
                      variables: {
                        studentId: params.studentId
                      },
                      data: nextSessions
                    })
                  },
                }).then((res: any) => {

                  setMarkedId(null)
                })
              }}>
                Confirm
              </Button>)}
          </Box>
        </Box>
      </Modal>
    </LoadingWrapper>
  );
}
