import React, { useContext, useState, useEffect } from "react";
import { defaultAssetsToLoad } from "./assetsToLoad";

export type PreloadImagesContent = {
    handlePreloadImages: (images: string[]) => void;
    loadingImages: boolean;
    loadedImages: string[]
}

const PreloadImagesContext = React.createContext<PreloadImagesContent>({
    handlePreloadImages: () => { },
    loadingImages: true,
    loadedImages: []
});

PreloadImagesContext.displayName = 'PreloadImagesContext';

const usePreloadImages = () => useContext(PreloadImagesContext)

const PreloadImagesProvider = ({ children }: { children: React.ReactNode }) => {
    const [loadedImages, setImages] = useState<string[]>([]);
    const [loadingImages, setLoadingImages] = useState(true);

    useEffect(() => {
        console.log(`Preloading images:`, {
            ...defaultAssetsToLoad
        });
        handlePreloadImages(defaultAssetsToLoad)
    }, [])

    const loadImages = (images: string[]) => {
        const newImages: string[] = [];
        images.forEach((_image: string) => {
            if (loadedImages.includes(_image)) {
                return
            } else newImages.push(_image);
        });
        newImages.forEach((src: string, index: number) => {
            console.log("loading", src)
            const _img = new Image();
            _img.src = src;
            _img.onload = () => {
                if (index === images.length - 1) {
                    console.log("loaded", src)
                    setLoadingImages(false);
                    setImages([...loadedImages, ...newImages]);
                }
            }
        })
    }

    const handlePreloadImages = (images: string[]) => {
        loadImages(images);
    };

    return (
        <PreloadImagesContext.Provider
            value={{
                loadingImages: Boolean(loadingImages),
                handlePreloadImages: handlePreloadImages,
                loadedImages
            }} >
            <div style={{ height: "0px", display: "hidden" }}>
                {loadedImages.map((src: string, index: number) => <img key={`${src}-${index}`} style={{ display: "none" }} alt={`${index}-preloaded`} src={src} />)}
            </div>
            {children} </PreloadImagesContext.Provider>
    );
};

export { usePreloadImages, PreloadImagesProvider };
