import { LinearProgress } from "@mui/material";
import {
    GridCallbackDetails,
    GridColDef,
    GridInputRowSelectionModel,
    GridRowModel,
    GridRowParams,
    GridRowSelectionModel,
    GridSlotsComponent,
} from "@mui/x-data-grid";
import { UncapitalizeObjectKeys } from "@mui/x-data-grid/internals";
import React, { useEffect, useRef, useState } from "react";

import DataGridStyled from "../StyledGrid";

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

type TableState = {
    rows: GridRowModel[],
    searchQuery: string | null
}

interface PageTableSelectionProps {
    rowSelectionModel?: GridInputRowSelectionModel;
    onRowSelectionModelChange?: (
      rowSelectionModel: GridRowSelectionModel,
      details: GridCallbackDetails
    ) => void;
  }

export type RenderToolbarProps = {
    searchQuery: string;
    onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSearchClear: () => void;
  };

export type TableProps = {
    columns: GridColDef[]
    rows: GridRowModel[]
    pageSize: number
    loading: boolean
    slots?: Omit<UncapitalizeObjectKeys<Partial<GridSlotsComponent>>, 'LoadingOverlay'>
    renderToolbar?: (props: RenderToolbarProps) => void
    isRowSelectable?: (params: GridRowParams<any>) => boolean
    disableBoxShadow: boolean
    checkboxSelection?: boolean
    selectionProps?: PageTableSelectionProps;
    onRowSelectionModelChange?: (
        rowSelectionModel: GridRowSelectionModel,
        details: GridCallbackDetails
      ) => void
    autoHeight?: boolean
}

function useCallOnce(callback: () => void) {
    const callbackRef = useRef(false);
    useEffect(() => {
        if (!callbackRef.current) {
            callbackRef.current = true;
            callback();
        }
    }, [callback]);
}

export default function Table({
    columns,
    rows,
    pageSize = 25,
    loading = false,
    slots,
    renderToolbar,
    isRowSelectable,
    disableBoxShadow = false,
    checkboxSelection = false,
    selectionProps,
    onRowSelectionModelChange,
    autoHeight = false
}: TableProps) {

    const [tableState, setTableState] = useState<TableState>({ 
        rows: [], 
        searchQuery: null 
    });

    const [preventRowSelectionModelChangeCall, setPreventRowSelectionModelChangeCall] = useState<boolean>(true);

    useCallOnce(() => {
        console.log('SHOULD CALL ONCE');
    });

    useEffect(() => {
        setTableState(prevState => ({
            ...prevState,
            rows
        }))
    }, [rows]);

    const handleSearch = (searchValue: string) => {
        const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
        const filteredRows = [...rows].filter(row => {
            return Object.keys(row).some((field: any) =>  
                row[field] 
                    ? searchRegex.test(row[field].toString()) 
                    : false
            );
        });

        setTableState(prevState => ({
            ...prevState,
            searchQuery: searchValue,
            rows: filteredRows
        }))
    };

    const handleSearchClear = () => {
        setTableState(prevState => ({
            ...prevState,
            searchQuery: ''
        }))
    }

    return (
        <div style={{ width: "100%" }}>
            {renderToolbar && 
                renderToolbar({ 
                    searchQuery: tableState.searchQuery ?? '', 
                    onSearch: (event) => handleSearch(event.target.value), 
                    onSearchClear: handleSearchClear
                })
            }

            <DataGridStyled
                loading={loading}
                columns={columns}
                rows={tableState.rows}
                getRowHeight={autoHeight ? () => 'auto' : undefined}
                // paginationModel={{ page: 1, pageSize }}
                pageSizeOptions={[5]}
                slots={{
                    ...slots,
                    loadingOverlay: LinearProgress,
                }}
                isRowSelectable={isRowSelectable}
                {...selectionProps}
                onRowSelectionModelChange={(model, details) => {

                    if (preventRowSelectionModelChangeCall && model.length === 0 && !details.reason) {
                        setPreventRowSelectionModelChangeCall(false);
                        return undefined;
                    }

                    if (onRowSelectionModelChange)
                        return onRowSelectionModelChange(model, details)

                    return undefined;
                }}
                disableBoxShadow={disableBoxShadow}
                disableColumnSelector={true}
                disableRowSelectionOnClick
                checkboxSelection={checkboxSelection}
                autoHeight
            />
        </div>
    );
}
