import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from '@mui/styles';
import { DataGrid, GridApi, GridColDef } from '@mui/x-data-grid';
import DateCell from "../../../../components/DateCell";
import useActionItems from "../../hooks/useActionItems";

const useStyles = makeStyles({
    root: {
        '& .MuiDataGrid-autoHeight': {
            minHeight: "212px"
        },
        '& .results-grid': {
            backgroundColor: "#F03F3F",
        },
        '& .results-grid--Severe': {
            backgroundColor: "#FEE2E2",
        },
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: "#8E1C3B",
            color: "#fff"
        },
        "& .MuiDataGrid-root": {
            background: "#fff",
            border: "none",
            boxShadow: "rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px"
        },
        "& .MuiDataGrid-columnSeparator": {
            display: "none"
        },
        "& .MuiDataGrid-sortIcon": {
            color: "#fff"
        },
        "& .MuiDataGrid-menuIconButton": {
            color: "#fff"
        },
        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
        {
            outline: "none",
        },
    },
});

export interface RiskWarningGridProps {
    onClickResults: (value: any) => void
}

function RiskWarningGrid({ onClickResults}: RiskWarningGridProps) {

    const classes = useStyles();

    const { actionItems } = useActionItems();

    const columns: GridColDef[] = [
        {
            field: 'createdAt',
            width: 200,
            renderCell: ({value}) => {
                return value ? <DateCell value={value.toString()} /> : '--'
            }
        },
        { field: 'name', flex: 1 },
        { field: 'urgency', width: 200 },
        {
            align: "center",
            sortable: false,
            resizable: false,
            editable: false,
            disableColumnMenu: true,
            field: "",
            width: 80,
            headerName: "",
            renderCell: (params) => {
                const onClick = () => {
                    const api: GridApi = params.api;
                    onClickResults(api.getRow(params.id))
                };

                return <IconButton onClick={onClick}><FileCopyIcon /></IconButton>;
            }
        },

    ];

    return (
        <Collapse unmountOnExit={false} in={Boolean(actionItems.length)} timeout={250} collapsedSize={0} orientation="vertical">
            <Box component="div" sx={{ maxWidth: "100%" }} className={classes.root}>
                <DataGrid
                    rows={actionItems}
                    columns={columns}
                    columnHeaderHeight={0}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 6 }
                        }
                    }}
                    slots={{ toolbar: ActionsNeededToolbar }}
                    slotProps={{
                        toolbar: {
                            title: `Actions Needed`,
                        }
                    }}
                    disableColumnSelector
                    disableRowSelectionOnClick
                />
            </Box>
        </Collapse>
    );
}

const ActionsNeededToolbar = ({ title }: any) => {
    return (
        <Box component="div" sx={{ padding: "8px 16px", backgroundColor: "#8E1C3B", color: "#fff" }}>
            <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h4" sx={{ textAlign: "left" }}>
                    {title}
                </Typography>
            </Box>
        </Box>
    )
}

export default RiskWarningGrid
