export type AppStorageNames = {
    readonly PERSON_AUTH: string,
    readonly PLAYER_AUTH: string,
    readonly PLAYER_DATA: string,
    readonly SESSION: string
}

export type AppStorage = {
    names: AppStorageNames
}

export const storage = {
    names: {
        PERSON_AUTH: 'personAuth',
        PLAYER_AUTH: 'playerAuth',
        PLAYER_DATA: 'playerData',
        SESSION: 'session'
    }
} as AppStorage;
