import { Alert, Box, Button, Container, Fade, Typography } from "@mui/material";

function defaultRetry() {
    window.location.reload();
}

export default function NetworkError({ error, loading, onRetry }: { error: boolean, loading: boolean, onRetry?: () => void }) {
    if (error) {
        return (
            <Container maxWidth="xl" sx={{ marginTop: 4 }}>
                <Fade appear in timeout={300}>
                    <Alert icon={false} variant="outlined" severity="warning" sx={{ padding: 4 }}>
                    <Box component="div">
                        <Typography variant="h6">Network Error</Typography>
                        <Typography variant="body1">
                            Cannot contact services vital for the operation of this application. The required services appear offline.
                        </Typography>
                        <Button 
                            sx={{ marginTop: 2.5 }} 
                            color="warning" 
                            variant="contained"  
                            onClick={onRetry ? onRetry : defaultRetry} 
                            disabled={loading}
                        >
                            Reload Application
                        </Button>
                    </Box>
                    </Alert>
                </Fade>
            </Container>
        );
    }

    return null;
}